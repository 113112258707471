import { TrackOrigin } from './trackOrigin';
import { Subject } from 'rxjs';
import { Config } from '../services/config';
import { RemoteAudioType } from '@service/vo/remote/remote-objects';

export enum QueueOrigin{
  none = "none",
  waiting = "waiting",
  radio = "radio",
  remote =  "remote"
}

export interface AudioProperties{
  id: number;
  title: string;
  group: string;
  duration: number;

  type: RemoteAudioType;

  origin: TrackOrigin;

  canPerformTrackActions: boolean; //search, add to playlist, ..
}

export interface PlayableAudio extends AudioProperties{
  canCrossFade: boolean;
  canLoadMediaUrls: boolean;

  //can be null, then there is no public url
  publicUrl: string;

  toString(): string;
}

export class AudioFile implements PlayableAudio{
  "@type": string;

  id: number;
  title: string;
  group: string;
  duration: number;
  location: string;

  origin: TrackOrigin;

  queueOrigin: QueueOrigin = QueueOrigin.none

  canCrossFade = true;
  canLoadMediaUrls = true;
  canPerformTrackActions = true;

  type = null;

  public get publicUrl():string{
    let publicUrl = Config.PUBLIC_URL_PREFIX + this.location;
    //Bypass is done through the config
    //publicUrl = publicUrl.includes("?")?publicUrl+"&ngsw-bypass=true":publicUrl + "?ngsw-bypass=true";
    return publicUrl;
  }

  constructor(audioFile?: AudioFile){
    if (audioFile){
      this["@type"] = audioFile["@type"];
      this.id = audioFile.id;
      this.title = audioFile.title;
      this.group = audioFile.group;
      this.duration = audioFile.duration;
      this.location = audioFile.location;
      this.origin = new TrackOrigin(audioFile.origin);
    }
  }

  //event when an action on the audioFile completes (for visual feedback)
  private trackActionDoneSource = new Subject();
  trackActionDoneSource$ = this.trackActionDoneSource.asObservable();
  public emitTrackActionDone(){
    this.trackActionDoneSource.next(null);
  }

  public toString(): string {
    return 'AudioFile ' + this.id + ': ' + this.group + ' - ' + this.title;
  }

  static arrayToString(audioFiles: AudioFile[]): string {
    let output = 'audioFile array is null';
    if (audioFiles) {
      output  = audioFiles.length + ' audioFiles: [';
      let first = true;
      for(const audioFile of audioFiles) {
        if (!first) {
          output = output + ', ';
        }
        first = false;
        output = output + audioFile.toString();
      }
      output = output + ']';
    }
    return output;
  }
}


