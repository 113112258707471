<div
  class="wrapper"
>

    <div *ngIf="changeableParameters == null || changeableParameters.length === 0" class="no-parameters">
      {{'musicChannel.musicCollection.tweak.noTweaks' | translate}}
    </div>

    <div
      class="field-container"
      *ngFor="let param of changeableParameters; let i = index; let last = last"
      [class.bottom-space]="last"
    >
      <tun-scrollable-text class="title" [text]="param.name | uppercase"></tun-scrollable-text>


      <div class="field checkboxes" *ngIf="isCheckboxSelectionParameter(param)">
        <ng-container *ngFor="let val of param.value">
          <button
            class="checkboxButton {{changeableParametersColor}}"
            [class.checked]="val.selected === undefined ? true : val.selected"
            (click)="onToggleValue(val)">
            {{val.value}}
          </button>
        </ng-container>
      </div>

      <div class="field" *ngIf="isSliderRangeSelectionParameter(param)">

       <tun-text-slider-v5
        [options]="param | mapSliderOptionsV5"
        [sliderColor]="changeableParametersColor"
        [selectedMinValue]="minSelectedValue(param)"
        [selectedMaxValue]="maxSelectedValue(param)"
        (selectedMinValueEmitter)="adjustMinValue(param, $event)"
        (selectedMaxValueEmitter)="adjustMaxValue(param, $event)"
       >
       </tun-text-slider-v5>
      </div>

      <div class="field" *ngIf="isSingleLabelTextSliderParameter(param)">

        <tun-text-slider-v5
        [options]="param | mapSliderOptionsV5"
        [sliderColor]="changeableParametersColor"
        [selectedMinValue]="param.lowerSelection"
        [selectedMaxValue]="param.upperSelection"
        [isSingleLabel]="true"
        [label]="param.name"
        (selectedMinValueEmitter)="adjustMinValue(param, $event)"
        (selectedMaxValueEmitter)="adjustMaxValue(param, $event)"
        >
        </tun-text-slider-v5>
       </div>




      <div
        class="field flexible-slider"
        *ngIf="isFlexibleSliderParameter(param)"
      >
        <tun-flexible-slider-v5
          [sliderColor]="changeableParametersColor"
          [minValue]="param.minimum"
          [maxValue]="param.maximum"
          [selectedMinValue]="param.lowerSelection"
          [selectedMaxValue]="param.upperSelection"
          [label]="
            param.metadataDisplayName === 'year'
              ? ''
              : param.metadataDisplayName
          "
          (selectedMinValueEmitter)="adjustMinValue(param, $event)"
          (selectedMaxValueEmitter)="adjustMaxValue(param, $event)"
        >
        </tun-flexible-slider-v5>
      </div>

    </div>
</div>
