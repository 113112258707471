<div class="wrapper">

  <div class="imageWrapper">
    <img class="backgroundImage" [src]="imageUrl$ | async">
    <div class="gradientLayer"></div>
  </div>

  <div class="contentWrapper {{showingPopup ? 'blurredContent' : ''}}">

    <div class="topContent">

      <div class="backHeader">
        <button class="icon-button icon-back-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>

        <div class="title">
          {{title$ | async}}
        </div>

        <!--
        <div *ngIf="(recommendedMusicCollections$ | async).length > 0" class="recommendation-title-container">
          <tun-ai-stars-v5 class="icon icon-scaled-svg-button ai-icon"></tun-ai-stars-v5>
          <div class="recommendation-text">
            <span translate>musicChannel.recommendation.info.text</span>
            <span click="onStartRecommendations()" class="link-button">Start tips</span>
          </div>
          <button class="icon-button" (click)="onCloseRecommendationInfo()">
            <tun-close-v5 class="icon icon-scaled-svg-button"></tun-close-v5>
          </button>
        </div>
        -->
        <!--
        <div *ngIf="(recommendedMusicCollections$ | async).length > 0" class="recommendation-title-container">
          <tun-ai-stars-v5 class="icon icon-scaled-svg-button ai-icon"></tun-ai-stars-v5>
          <div class="recommendation-text">
            <span translate>musicChannel.recommendation.info.text</span>
          </div>

          <button (click)="onStartRecommendations()" class="link-button" translate>
            Start tips
          </button>

        </div>
        -->

        <div class="spacer icon-large-scaled-svg-button"></div>

      </div>

      <div class="titleContainer">


        <tun-play-music-selection-button
          class="play-button"
          [itemToStart]="showingMusicChannel$ | async"
          (itemIsStarting)="onItemIsStarting()"
        >

        </tun-play-music-selection-button>

        <!-- Show info on the current state-->
        <div
          class="title-info-container"
        >
          <span>{{musicChannelInfoTitle$ | async}}</span>
          <span [innerHTML]="musicChannelInfoText$ | async"></span>
        </div>

      </div>
    </div>


    <div class="scrollContainer">
      <ng-container *ngIf="showingMusicChannel$ | async">
          <div
            class="grid-container"
            *ngFor="let item of amountOfMusicBlockGroups; let i = index"
            [style.padding-bottom]="
              i !== 3 ? '1.5rem' : '0'
            "
            [style.margin-top.rem]="i === 0 ? 1 : 0"
            [style.margin-bottom.rem]="i === 3 ? 2 : 0"
          >
            <tun-music-collection-view
              *ngFor="let item of amountOfMusicBlocksPerGroup; let j = index"
              [musicCollection]="
              musicCollections$ | async | musicCollectionSorter: i:j
              "
              [recommendedMusicCollections]="recommendedMusicCollections$ | async"
              class="musicCollection"
              (tweakMusicCollection)="onTweakMusicCollection($event)"
              (toggleMusicCollectionSelection)="onToggleMusicCollectionSelection($event)"
            >
            </tun-music-collection-view>
          </div>
      </ng-container>
    </div>
  </div>


  <!-- Popup: tweak musicCollection -->
  <div #tweakPopupWrapper *ngIf="showTweakPopup" class="popupWrapper" @slideUpAnimation (mousedown)="onClickOutside($event, tweakPopupWrapper)">
    <tun-overlay-tweak-music-collection
      class="tweak-musicCollection-overlay"
      [musicChannel]="showingMusicChannel$ | async"
      [musicCollection]="tweakMusiCollection"
      (parametersChanged)="onParametersChanged($event)"
      (close)="onCloseTweakPopup()"
    >
    </tun-overlay-tweak-music-collection>
  </div>

</div>

