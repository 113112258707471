import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrackTableItem } from '@components/components-v5/track-list-view/track-list-view.component';
import { AudioFile } from '@model/audioFile';
import { AppV5StateService, TunifyV5Tab } from '@service/app-v5/app-v5-state.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Playlist } from '@model/playlist';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { PlaylistService } from '@service/playlist.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { MatSnackBar } from '@angular/material/snack-bar';
import { slideUpAnimation } from '@util/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { SubscriptionsService } from '@service/subscriptions.service';
import { PlayStateService } from '@service/play-state.service';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { Context } from '@model/context';

export enum SearchState{
  normal,
  searching,
  searchError,
  none
}

@Component({
  selector: 'tun-search-music-collection-result-content-view',
  templateUrl: './search-music-collection-result-content-view.component.html',
  styleUrls: ['./search-music-collection-result-content-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})
export class SearchMusicCollectionResultContentViewComponent implements OnInit, OnDestroy{
  public LOGGER_NAME = SearchMusicCollectionResultContentViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public TunifyColor = TunifyColor
  public TunifyCheckBoxColor = TunifyCheckBoxColor

  public get showingPopup(){
    return this.showChoosePlaylistOverlay;
  }
  public showChoosePlaylistOverlay = false

  public get showingMusicChannel$(){
    return this.appV5StateService.showDetailsForSearchMusicChannel$
  }

  public get musicCollection$(){
    return this.appV5StateService.showDetailsForSearchMusicCollection$
  }

  public get hasTweaks$(){
    return this.appV5StateService.showDetailsForSearchMusicCollection$
      .pipe(
        map(musicCollection => {
          return musicCollection && musicCollection instanceof Context
        })
      )
  }


  public get previousSectionTitle(){
    if (this.appV5StateService.showDetailsForSearchMusicCollection){
      if (this.appV5StateService.showDetailsForSearchMusicChannel){
        return this.appV5StateService.showDetailsForSearchMusicChannel.name;
      }
    }
    return null;
  }

  public get title(){
    if (this.appV5StateService.showDetailsForSearchMusicCollection){
      return this.appV5StateService.showDetailsForSearchMusicCollection.title
    }
    return ""
  }

  //make the enum available in the html template
  public SearchState = SearchState;

  get searchState(): SearchState{
      if (this.appV5StateService.loadingTracksForMusicCollection){
        return SearchState.searching;
      }else if (this.appV5StateService.loadingTracksForMusicCollectionError){
        return SearchState.searchError;
      }else if (this.appV5StateService.tracksForMusicCollection){
        return SearchState.normal;
      }
      return SearchState.none;
  }

  get audioFileProperty$(){
    return this.zoneConfigurationService.audioFileProperty$
  }

  get hasTracks$(){
    return this.appV5StateService.tracksForMusicCollection$
      .pipe(
        map(tracks => {
          return tracks && tracks.length > 0
        })
      )
  }

  get tracks$(){
    return this.appV5StateService.tracksForMusicCollection$;
  }

  constructor(
    private zoneConfigurationService: ZoneConfigurationService,
    private appV5StateService: AppV5StateService,
    private translateService: TranslateService,
    private musicManipulationService: MusicManipulationService,
    private queueService: QueueService,
    private loggerService: LoggerService,
    private playlistService: PlaylistService,
    private snackBar: MatSnackBar,
    private trackOverlayService: TrackOverlayService,
    private playingItemService: PlayingItemService
  ) {

  }

  private tracksChanged$ = new Subject<void>();
  ngOnInit(): void {
    this.tracks$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      () => {
        this.tracksChanged$.next();
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;


  }
  onBackToSearch(){
    this.appV5StateService.selectTunifyTab(TunifyV5Tab.SEARCH);
  }

  onBack(){
    this.appV5StateService.showSearchMusicCollectionInTunifyOrange(null);
  }

  backTooltipForSection(section: String){
    return this.translateService.instant('general.tooltip.backTo').replace("{0}", section);
  }

  refreshTooltip(){
    return this.translateService.instant('search.title.tooltip.refresh').replace("{0}", this.title);;
  }


  /* Handlers for header */

  public onPlayAll(){
    if (this.appV5StateService.tracksForMusicCollection){
      if (this.appV5StateService.tracksForMusicCollection.length > 0){
        const tracksToQueue: AudioFile[] = [];
        this.appV5StateService.tracksForMusicCollection.forEach(track => tracksToQueue.push(createClassObjectForAudioFile(track)));

        const trackToPlay = tracksToQueue.shift()
        this.musicManipulationService.playAudioFile(trackToPlay);

        if (tracksToQueue.length > 0){
          this.musicManipulationService.clearQueue();
          this.musicManipulationService.addAudioFilesToQueue(tracksToQueue);
        }

        this.checkedTracks = [];
      }
    }
  }

  public onRefresh(){
    this.appV5StateService.loadTracksForCurrentMusicCollection();
  }



  /* Handlers */

  public onShowOptions(track: AudioFile){
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = new TrackInfoOverlayData(track, new TrackInfoContext(TrackInfoContextMode.search));
  }

  public checkedTracks : AudioFile[] = [];
  onCheckedTracksChanged(checkedTracks: AudioFile[]){
    this.checkedTracks = checkedTracks;
  }
  onAddTracksToPlaylist(){
    this.showChoosePlaylistOverlay = true
  }

  /* Select playlist popup */
  onCloseSelectPlaylist(playlist: Playlist){
    if (playlist && this.checkedTracks.length > 0){
      this.playlistService.addAudioFileToPlaylist(playlist, this.checkedTracks)
      .pipe(
        takeUntil(
          merge(
            this.tracksChanged$,
            this.destroyed$
          )
        )
      ).subscribe(
        (asyncStatus) => {
          if (asyncStatus == AsyncStatus.RUNNING){
            /*
            const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
              duration: this.SNACKBAR_DURATION,
              panelClass: ['tunify-snackbar']
            });
            */

          }
          if (asyncStatus == AsyncStatus.COMPLETED){

              const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });

          }
        }
      );
    }
    this.showChoosePlaylistOverlay = false;
  }

  //General popup
  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;
    this.showChoosePlaylistOverlay = false;
    this.showTweakPopup = false;
  }


  //Tweak popup
  public showTweakPopup = false;
  public onTweak(){
    this.showTweakPopup = true
  }

  public onParametersChanged(){
    if (this.appV5StateService.showDetailsForSearchMusicCollection){
      this.appV5StateService.loadTracksForCurrentMusicCollection();
    }
  }

  public onCloseTweakPopup(){
    this.showTweakPopup = false;
  }


  //Track is playing
  isTrackPlaying(track: AudioFile){
    return this.playingItemService.isTrackPlaying(track);
  }

  isRealyPlaying(){
    return this.playingItemService.isPlaying;
  }


  //Track in queue
  public onAddChanged(value:boolean, track: AudioFile){
    if (value){
      this.queueService.addAudioFilesToQueue([track]);
    }else{
      //Remove is not supported
    }
  }

  public isTrackAdded(track: AudioFile){
    return this.queueService.queue && this.queueService.queue.filter(t => t.id == track.id).length > 0
  }




}
