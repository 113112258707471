<div class="wrapper">

  <div class="contentWrapper {{showingPopup ? 'blurredContent' : ''}}">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button
          [matTooltip]="'search.back.tooltip' | translate"
          class="icon-button header-icon-button"
          (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="search-container">
        <tun-search-input
          class="search-field first-title-item"
          [canReceiveFocus]="canReceiveFocus"
          (searchfocus)="onSearchFieldFocus($event)"
          (search)="onSearch($event)"
          (autocomplete)="onAutocomplete($event)"
        >
      </tun-search-input>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- overlay wrapper for autocompletion -->
    <div class="overlay-wrapper">

      <div class="overlay-content {{showAutoComplete ? 'blurredContent' : ''}}">
        <!-- search result content -->

        <ng-container *ngIf="searchState != SearchState.none">

          <!-- header -->
          <div class="titleContent">

            <div class="remainingSpace">

            </div>

            <div class="titleContainer">

              <button
                [disabled]="!(hasTracks$ | async)"
                class="icon-button first-title-item {{checkedTracks.length > 0 ? 'no-focus' : ''}}"
                [matTooltip]="'trackOptions.list.playAll' | translate"
                (click)="onPlayAll()">
                <tun-play-icon-v5 class="icon colored-icon"></tun-play-icon-v5>
              </button>

              <div class="title">
                {{ searchTitle$ | async }}
              </div>

            </div>

            <div class="remainingSpace"></div>

          </div>

          <!-- Special search states indicator -->

          <div
            *ngIf="searchState != SearchState.normal"
            [ngSwitch]="searchState"
            class="feedback-container"
          >
            <div
              *ngSwitchCase="SearchState.searchError"
              class="error-feedback-container"
            >
              <p>
                {{'search.status.failed' | translate }}
              </p>
              <button (click)="retryClick()" class="retryButton">
                <p>
                  {{'general.tryAgain' | translate }}
                </p>
              </button>
            </div>

            <ng-container *ngSwitchCase="SearchState.searching">
              <tun-loader-v5 class="loader"></tun-loader-v5>
            </ng-container>

          </div>

      <tun-selectable-track-list-view
        *ngIf="searchState == SearchState.normal"
        class="trackTable"
        [tracks]="tracks$ | async"
        [checkedTracks]="checkedTracks"
        [audioFileProperty]="audioFileProperty$ | async"
        (checkedTracksChanged)="onCheckedTracksChanged($event)"
        (addTracksToPlaylist)="onAddTracksToPlaylist($event)"
        (showOptions)="onShowOptions($event)"
      >
      </tun-selectable-track-list-view>


    </ng-container>
      </div>

      <div
        *ngIf="showAutoComplete"
        class="overlay-content"
      >
        <div class="autocompletion-container">
          <tun-autocompletion
          [autocompleteValue]="lastAutocompleteValue"
          (selectAutocomplete)="onSelectAutocomplete($event)"
          class="autocompletion-field"
        >

        </tun-autocompletion>
        </div>

      </div>



    </div>





  </div>

   <!-- Popup: choose playlist-->
   <div #choosePlaylistPopupWrapper *ngIf="showChoosePlaylistOverlay" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
    <tun-overlay-select-playlist
      class="select-playlist-overlay"
      confirmButtonTranslateableText="playlist.addTrack.popup.button.commit"
      (closeWithPlaylist)="onCloseSelectPlaylist($event)"
    >
    </tun-overlay-select-playlist>
  </div>

</div>
