import { LoggerService } from "@service/loggers/logger.service";
import { Intercom, IntercomBootInput } from "@supy-io/ngx-intercom";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

export interface IntercomZoneConnection{
  zoneId: string;
  intercomUserHash: string;
}

export class IntercomConnectedZoneManager {

  private readonly LOGGER_CLASSNAME = IntercomConnectedZoneManager.name;

  private intercom: Intercom;
  private loggerService: LoggerService;

  //the vertical padding for the intercom chat window, set during login process
  public verticalPadding: number = 10;

  constructor(
      intercom: Intercom,
      loggerService: LoggerService
  ){
      this.intercom = intercom;
      this.loggerService = loggerService;

      //at start -> init with an anonymous visitor mode intercom session
      this.login();
  }

  private _intercomZoneConnection: IntercomZoneConnection;
  public connectZoneToIntercom(intercomZoneConnection: IntercomZoneConnection){
      if (this._intercomZoneConnection != intercomZoneConnection){
          this._intercomZoneConnection = intercomZoneConnection;
          this.adjustIntercom();
      }
  }
  public get intercomZoneConnection(): IntercomZoneConnection{
    return this._intercomZoneConnection;
  }


  private currentConnectedZoneIdSubject = new BehaviorSubject<string>(null);
  public currentConnectedZoneId$ = this.currentConnectedZoneIdSubject.asObservable();

  private set currentConnectedZoneId(value: string){
    this.currentConnectedZoneIdSubject.next(value);
  }
  private get currentConnectedZoneId(): string{
    return this.currentConnectedZoneIdSubject.value;
  }

  private adjustIntercom(){

    if (this.intercomZoneConnection != null){

      if (this.currentConnectedZoneId != null && this.currentConnectedZoneId != this.intercomZoneConnection.zoneId){
        //log out if the wrong zone is connected
        this.loggerService.warn(this.LOGGER_CLASSNAME, 'adjustIntercom', 'Wrong connected zone (' + this.currentConnectedZoneId + ' instead of ' + this.intercomZoneConnection.zoneId + ') -> logging out from intercom before logging in');
        this.logout();
      }else if (this.currentConnectedZoneId == null ){
        //log out if we were connected as anonymous visitor
        this.logout();
      }

      //connect zone if none is connected by now
      if (this.currentConnectedZoneId == null){
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustIntercom', 'zone not connected to intercom -> going to connect ' + this.intercomZoneConnection.zoneId + '.');
        this.login();
      }else{
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustIntercom', 'zone ' + this.currentConnectedZoneId + ' already used for intercom connection');
      }
    }else{
      //log out if the wrong zone is connected
      if (this.currentConnectedZoneId != null){
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustIntercom', 'Going to disconnect ' + this.currentConnectedZoneId + '.');
        this.logout();

        //start an anonymous visitor mode intercom session
        this.login();
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustIntercom', 'No zone connected to intercom -> nothing to do.');
      }
    }
  }

  private login(){
    const intercomBootInput: IntercomBootInput = {
      app_id: environment.intercomId,
      custom_launcher_selector: '#messagesButton',

      alignment: 'right',      //top does not work, only left and right
      horizontal_padding: 20,
      vertical_padding: this.verticalPadding,
    };

    if (this.intercomZoneConnection != null){
      intercomBootInput.user_id = this.intercomZoneConnection.zoneId;
      intercomBootInput.user_hash = this.intercomZoneConnection.intercomUserHash;
    }

    this.intercom.boot(intercomBootInput);

    this.currentConnectedZoneId = this.intercomZoneConnection ? this.intercomZoneConnection.zoneId : null;
  }

  private logout(){
    this.intercom.shutdown();
    this.currentConnectedZoneId = null;
  }

}
