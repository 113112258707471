<div
  #calendarItemRef
  class="calendar-item {{isSelected ? 'selected' : ''}} {{changingTime ? 'changingTime' : ''}}"
  [style.height.rem]="(baseREM * calendarItem.duration) / 60 - 0.2"
  [style.top.rem]="
    baseREM / 2 +
    baseREM * calendarItem.startHour +
    ((calendarItem.startMinutes / 30) * baseREM) / 2
  "
>

  <div
    *ngIf="changingTime && !performingActionOnItem"
    class="extender top"
    (mousedown)="onExtenderMouseDown($event, true)"
  >
   <tun-grabber-icon-v5 class="icon extender-icon"></tun-grabber-icon-v5>
  </div>

  <div
    *ngIf="changingTime && !performingActionOnItem"
    class="extender bottom"
    (mousedown)="onExtenderMouseDown($event, false)"
  >
   <tun-grabber-icon-v5 class="icon extender-icon"></tun-grabber-icon-v5>
  </div>

  <div
    #calendaritem
    class="content-container"
  >
    <!--



    <div
      class="label-cover"
      [style.opacity]="
        inEditMode &&
        !isMouseDown &&
        !hoverOverTopExtender &&
        !hoverOverBottomExtender &&
        !hoverOverDeleteIcon &&
        hoverOverLabel
          ? 0.1
          : 0
      "
    ></div>
    -->
    <div
      #calendaritemtitle
      *ngIf="!performingActionOnItem"
      class="label {{(isMouseDown$ | async) ? 'unselectable':''}}"
    >
      <tun-scrollable-text class="calendarItemTitle" [text]="calendarItem.title"></tun-scrollable-text>

      <ng-container *ngIf="!editMode && !changingTime && !performingActionOnItem">
        <tun-music-play-animation
          class="playAnimationContainer"
          *ngIf="showPlayAnimation$ | async"
          [playing]="animatePlayAnimation$ | async"
          [musicPlayAnimationColors]="playAnimationColors"
        >
        </tun-music-play-animation>
      </ng-container>

    </div>
    <!--
    <div class="animation" [style.opacity]="showAnimation ? 1 : 0">
      <tun-music-play-animation
        [playing]="isPlaying"
        [selected]="isSelected"
        [musicPlayAnimationColors]="musicPlayAnimationColors"
      ></tun-music-play-animation>
    </div>
    -->

    <ng-container *ngIf="editMode && !changingTime && !performingActionOnItem">
      <button class="icon-button" (click)="onRemove()">
        <tun-delete-icon-v5 class="icon icon-scaled-svg-button"></tun-delete-icon-v5>
      </button>

      <button class="icon-button" [matMenuTriggerFor]="menu">
        <tun-track-options class="icon icon-scaled-svg-button"></tun-track-options>
      </button>

      <mat-menu #menu="matMenu">
        <button class="option-button" (click)="onChangeTime()">
          <tun-clock-icon-v5 class="icon option-button-icon"></tun-clock-icon-v5>
          <span translate>calendars.edit.item.popup.time</span>
        </button>
        <button class="option-button" (click)="onChangeMusicChannel()">
          <tun-musicnote-icon-v5 class="icon option-button-icon"></tun-musicnote-icon-v5>
          <span translate>calendars.edit.item.popup.musicChannel</span>
        </button>
        <button class="option-button" (click)="onChangePlaylist()">
          <tun-menu-playlist-icon-v5 class="icon option-button-icon"></tun-menu-playlist-icon-v5>
          <span translate>calendars.edit.item.popup.playlist</span>
        </button>
        <button class="option-button" (click)="onTweak()">
          <tun-tweak-context-icon-v5 class="icon option-button-icon"></tun-tweak-context-icon-v5>
          <span translate>calendars.edit.item.popup.tweak</span>
        </button>
      </mat-menu>


    </ng-container>

    <ng-container *ngIf="performingActionOnItem">
      <tun-loader-v5 *ngIf="calendarItem.removing" text="general.remove"></tun-loader-v5>
      <tun-loader-v5 *ngIf="calendarItem.creating" text="calendar.edit.item.creating"></tun-loader-v5>
      <tun-loader-v5 *ngIf="calendarItem.saving" text="general.save"></tun-loader-v5>
    </ng-container>

    <ng-container *ngIf="changingTime">
      <button class="icon-button" (click)="onCloseChangeTime()">
        <tun-close-v5 class="icon icon-scaled-svg-button"></tun-close-v5>
      </button>
    </ng-container>



    <!--
    <div
      *ngIf="inEditMode && !performingActionOnItem"
      class="delete-icon-container"
      (mouseover)="hoverOverDeleteIcon = true"
      (mouseout)="hoverOverDeleteIcon = false"
      (click)=onDeleteCalendarItem()
      [style.max-height.px]="heightPerItem"
    >
      <div
        class="delete-icon-cover"
        [style.width.px]="heightPerItem / 2"
        [style.height.px]="
          calendarItem.duration >= 60
            ? calendarItem.duration >= 90
              ? heightPerItem
              : heightPerItem - 4
            : heightPerItem / 2 - 4
        "
        [style.opacity]="!isMouseDown && hoverOverDeleteIcon ? 0.2 : 0"
        [style.padding-left.px]="heightPerItem / 4"
        [style.padding-right.px]="heightPerItem / 4"
      ></div>
      <div
        class="delete-icon"
        [style.width.px]="heightPerItem / 2"
        [style.height.px]="heightPerItem / 2"
        [style.padding-left.px]="heightPerItem / 4"
        [style.padding-right.px]="heightPerItem / 4"
        [style.color]="
          hoverOverDeleteIcon && !isMouseDown
            ? selected
              ? '#000'
              : '#FFF'
            : selected
            ? selectedMusicCategoryBlockColors._textColor
            : unselectedMusicCategoryBlockColors._textColor
        "
      >
        <tun-delete-icon></tun-delete-icon>
      </div>
    </div>
    -->
    <!--
    <div  *ngIf="performingActionOnItem"
        [style.max-height.px]="heightPerItem">
      <tun-loader text=""></tun-loader>
    </div>
    -->



  </div>

  <!--
  <div
    *ngIf="inEditMode && !performingActionOnItem"
    class="tweak-icon-container"
    (mouseover)="hoverOverTweakIcon = true"
    (mouseout)="hoverOverTweakIcon = false"
    (click)="openCalendarItemTweakPanel()"
    [style.background-color]="
      selected
        ? hoverOverTweakIcon && !isMouseDown
          ? selectedMusicCategoryBlockColors._iconBackgroundHoverColor
          : selectedMusicCategoryBlockColors._iconBackgroundColor
        : hoverOverTweakIcon && !isMouseDown
        ? unselectedMusicCategoryBlockColors._iconBackgroundHoverColor
        : unselectedMusicCategoryBlockColors._iconBackgroundColor
    "
    [style.border-color]="
      selected
        ? selectedMusicCategoryBlockColors._iconBorderColor
        : unselectedMusicCategoryBlockColors._iconBorderColor
    "
  >-->
  <!--
    <div
      class="tweak-icon"
      [style.width.px]="heightPerItem / 2"
      [style.height.px]="heightPerItem / 2"
      [style.padding-left.px]="heightPerItem / 4"
      [style.padding-right.px]="heightPerItem / 4"
    >
      <tun-settings-icon
        *ngIf="!calendarItem.changeableParametersLoaded || calendarItem.changeableParameter"
        [color]="
          hoverOverTweakIcon && !isMouseDown
            ? '#FFF'
            : selected
            ? selectedMusicCategoryBlockColors._iconColor
            : unselectedMusicCategoryBlockColors._iconColor
        "
      ></tun-settings-icon>
      <tun-list-icon
        *ngIf="calendarItem.changeableParametersLoaded && !calendarItem.changeableParameter"
        [color]="
          hoverOverTweakIcon && !isMouseDown
            ? '#FFF'
            : selected
            ? selectedMusicCategoryBlockColors._iconColor
            : unselectedMusicCategoryBlockColors._iconColor
        "
      >
      </tun-list-icon>
    </div>
  </div>
  -->
</div>
