import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { CalendarGroup } from '@model/calendarGroup';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { LoggerService } from '../loggers/logger.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Calendar } from '@model/calendar';
import { ZoneConfigurationService } from './zone-configuration.service';
import { CalendarGroupApiService, DTO_LoadCalendarGroupsResponse } from '../api/calendar-group-api.service';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { CalendarService } from './calendar.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarGroupService implements OnDestroy {

  private LOGGER_CLASSNAME = 'MusicChannelService';

  //helper observable that triggers when the active zone changes
  //this will cancel any running request
  private activeZoneChanged$ = new Subject<void>();

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private zoneConfigurationService: ZoneConfigurationService,
    private calendarGroupApiService: CalendarGroupApiService,
    private calendarService: CalendarService,
    private loggerService: LoggerService
  ) {

    this.zoneConnectionsService.activeZoneConnection$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (zoneConnection) => {

          //zoneConnection changed -> unload
          this.clearData()

          if (zoneConnection != null) {
            // we just logged in, start loading
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnectionSubscription', 'logged in ... start loading calendarGroups if possible');
            this.loadDataIfPossible();
          } else {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnectionSubscription', 'logged out ... calendarGroups were unloaded');

          }
        },
        (err: unknown) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnectionSubscription', 'error from activeZoneConnectionSubscription: ' + err);
        }
      );

    this.zoneConfigurationService.language$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          //start loading once the language is known. This will also reload the data when the language is changed.
          if (value != null) {
            this.loadDataIfPossible();
          }
        }
      );


      this.zoneConfigurationService.zoneConfigurationError$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (loadError) => {
          //When loading the zoneConfigration fails AND we did not load the calendarGroups: start loading without language
          if (loadError != null && this.calendarGroups == null && !this.loading) {
            this.loadDataIfPossible();
          }
        }
      );

  }

  private destroyed$ = new Subject<void>();
  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    this.activeZoneChanged$.next();
    this.activeZoneChanged$.complete();
    this.activeZoneChanged$ = null;

    this.calendarGroupsWillStartLoading$.complete();
    this.calendarGroupsWillStartLoading$ = null;
  }

  public loadDataIfNeeded(){
    if (!this.loading && this.calendarGroups == null){
      this.loadDataIfPossible();
    }
  }

  //load the data once all depending data is available
  private loadDataIfPossible() {
    if (this.zoneConnectionsService.activeZoneConnection != null && (this.zoneConfigurationService.language != null || this.zoneConfigurationService.zoneConfigurationError != null)) {
      this.loadCalendarGroups();
    }
  }

  private clearData() {
    //cancel any loading requests
    this.activeZoneChanged$.next();

    this._loading = false;
    this._loadingError = false;
    this._calendarGroups = null;
  }

  /**
     * loading
     * @type {boolean}
     * @private
     */
  private get _loading(): boolean {
    return this._loadingSubject.value;
  }
  private set _loading(value: boolean) {
    if (this._loading !== value) {
      this._loadingSubject.next(value);
    }
  }
  get loading(): boolean {
    return this._loading;
  }
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();

  /**
   * Error emitter for retrieving the musicChannelGroups
   * @type {string}
   * @private
   */
  private get _loadingError(): boolean {
    return this._loadingErrorSubject.value;
  }
  private set _loadingError(value: boolean) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }
  get loadingError(): boolean {
    return this._loadingError;
  }
  private _loadingErrorSubject = new BehaviorSubject<boolean>(false);
  public loadingError$ = this._loadingErrorSubject.asObservable();

  /**
   * musicChannelGroups
   * @type {CalendarGroup[]}
   * @private
   */
  private get _calendarGroups(): CalendarGroup[] {
    return this._calendarGroupsSubject.value;
  }
  private set _calendarGroups(value: CalendarGroup[]) {
    if (this._calendarGroups !== value) {
      this.createAllCalendarsArray(value);
      this._calendarGroupsSubject.next(value);
    }
  }
  get calendarGroups(): CalendarGroup[] {
    return this._calendarGroups;
  }
  private _calendarGroupsSubject: BehaviorSubject<CalendarGroup[]> = new BehaviorSubject<CalendarGroup[]>(null);
  public calendarGroups$: Observable<CalendarGroup[]> = this._calendarGroupsSubject.asObservable();


  /**
   * Versioning
   */
 private calendarGroupsTimestamp: number = undefined;
 private timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used when the data is fetched to check if we have the most recent version
 private refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)

 public handleCalendarGroupsUpdateInfo(timestamp: number){
   //keep track of the highest seen timestamp
   if (timestamp != undefined){
     if (this.timestampFromUpdateTrigger == undefined || this.timestampFromUpdateTrigger < timestamp) {
       this.timestampFromUpdateTrigger = timestamp;
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleCalendarGroupsUpdateInfo', 'Going to check for newest version:  ' + timestamp);
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleCalendarGroupsUpdateInfo', 'Already going to check for an even newer version:  ' + this.timestampFromUpdateTrigger + '(we just received: ' + timestamp + ')');
     }
   }else{
     this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleCalendarGroupsUpdateInfo', 'No timestamp info');
   }

   this.checkReloadCalendarGroupsNeeded();
 }

 private checkReloadCalendarGroupsNeeded(){
   if (this.timestampFromUpdateTrigger != undefined){
     //if we are not loading the calendarGroups -> fetch
     if (!this.loading) {
       if (this.timestampFromUpdateTrigger > this.calendarGroupsTimestamp){
         this.loadCalendarGroups();
       }else{
         this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadCalendarGroupsNeeded', 'Already have a newer version (' + this.calendarGroupsTimestamp + '), not going to reload for update trigger timestamp ' + this.timestampFromUpdateTrigger);
       }
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadCalendarGroupsNeeded', 'Not going to load right now: loading: ' + (this.loading ? 'true': 'false') + '.');
     }
   }
 }

public handleCalendarUpdateInfo(calendarId: number, timestamp: number){
   if (timestamp != undefined){

     if (this.calendarGroups){
      let found = false;
      this.calendarGroups.forEach(calendarGroup => {
        if (calendarGroup.calendars){
          calendarGroup.calendars.forEach(calendar => {
            if (calendar.calendarId == calendarId){
              found = true;
              if (calendar.timestampFromUpdateTrigger == undefined || calendar.timestampFromUpdateTrigger < timestamp){
                calendar.timestampFromUpdateTrigger = timestamp;
                this.checkReloadCalendarNeeded(calendar);
              }
            }
          });
        }
      });

      if (!found){
        this.loggerService.error(this.LOGGER_CLASSNAME, "handleCalendarUpdateInfo", "calendar " + calendarId + " not found.");
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, 'handleCalendarUpdateInfo', 'should handle updateInfo for calendar with id ' + calendarId + ', but calendar could not be found.');
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'handleCalendarUpdateInfo', 'should handle updateInfo for playlist with id ' + calendarId + ', but calendarGroups are not yet loaded.');
    }
  }else{
    this.loggerService.error(this.LOGGER_CLASSNAME, 'handleCalendarUpdateInfo', 'should handle updateInfo for playlist with id ' + calendarId + ', but no timestamp received.');
  }
}

 private checkReloadCalendarNeeded(calendar: Calendar){
   if (calendar.refetchNeededForConflict){
    this.calendarService.loadCalendarItems(calendar)
   }else if (calendar.timestampFromUpdateTrigger != undefined){
     if (calendar.timestampFromUpdateTrigger > calendar.timestamp){
       if (!calendar.saving && !calendar.calendarItemsLoading){
        this.calendarService.loadCalendarItems(calendar)
       }
     }else{
       this.loggerService.error(this.LOGGER_CLASSNAME, 'checkReloadCalendarNeeded', 'calendar ' + calendar.calendarId + ' has a newer version (' + calendar.timestamp + ') then seen in the updateInfo (' + calendar.timestampFromUpdateTrigger + ') -> going to ignore update info timestamp.');
     }
   }
 }

  //the subject to cancel any previous request
  private calendarGroupsWillStartLoading$: Subject<void> = new Subject<void>();
  public loadCalendarGroups(): Observable<CalendarGroup[]> {

    const loadObservable: Observable<DTO_LoadCalendarGroupsResponse> = this.calendarGroupApiService.loadCalendarGroups()
    if (loadObservable != null){

      this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadCalendarGroups', 'Going to load calendarGroups');
      this.calendarGroupsWillStartLoading$.next(null);

      this._loading = true;
      this._loadingError = false;

      loadObservable
        .pipe(
          takeUntil(
            merge(
              this.destroyed$,
              this.activeZoneChanged$,
              this.calendarGroupsWillStartLoading$
            )
          ),
          finalize(() => {
            this._loading = false;
            this.checkReloadCalendarGroupsNeeded()
          })
        )
        .subscribe(
          (data) => {

            let useResponse = true;
            if (this.timestampFromUpdateTrigger != undefined && this.timestampFromUpdateTrigger > data.timestamp) {
              //there is a newer resource version on the server -> refetch
              if (!this.refetchedToAvoidVersionConflict) {
                //we are only going to refetch once, to avoid endless loop of fetching data when there is something wrong with our version timestamps
                this.refetchedToAvoidVersionConflict = true;
                useResponse = false;
              } else {
                //ignore the newer version and start using this queue

                this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadCalendarGroups', 'loadCalendarGroups receives an older version number but we already refetched before -> just start working with this version');
              }
            }

            if (useResponse) {

              //reset the conflict flag once we use the queue
              this.refetchedToAvoidVersionConflict = false;
              //reset the highest seen timestamp
              this.timestampFromUpdateTrigger = undefined;

              if (data.calendarGroups.length > 0){
                const calendarGroups = data.calendarGroups.map(res => new CalendarGroup(res));
                this.mergeUpToDateCalendarDetails(calendarGroups);
                this._calendarGroups = calendarGroups;
              }else{
                this._calendarGroups = [];
              }

              this.calendarGroupsTimestamp = data.timestamp;

              this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadCalendarGroups', 'loadCalendarGroups done (timestamp: ' + this.calendarGroupsTimestamp + '): ' + this.calendarGroups.length + ' calendarGroups.');
            } else {
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadCalendarGroups', 'Not going to use result -> reload calendarGroups from the finalize block.');
            }
          },
          (error : unknown) => {
            let errMsg = 'Error';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }
            this.loggerService.error(this.LOGGER_CLASSNAME, 'loadCalendarGroups error', errMsg);
            this._loadingError = true;
          });

    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadCalendarGroups', 'Observable to load calendarGroups was not created');
    }

    return this.calendarGroups$;
  }

   //helper function that will merge all up-to-date details of our current calendarGroups into the new array.
  //This will avoid having to reload the calendarItems of unchanged calendars
  private mergeUpToDateCalendarDetails(calendarGroups: CalendarGroup[]){
    calendarGroups.forEach(calendarGroup => {
      if (calendarGroup.calendars){
        calendarGroup.calendars.forEach(calendar => {
          const knownCalendar = this.findCalendarById(calendar.calendarId)
          if (knownCalendar != null && knownCalendar.calendarItemsLoaded ){
            calendar.tranferUpToDateDetails(knownCalendar)
          }
        })
      }
    })
  }

  //helper array to look up calendars
  private allCalendars: Calendar[] = null;
  private createAllCalendarsArray(calendarGroups: CalendarGroup[]) {
    if (calendarGroups) {
      this.allCalendars = [];
      calendarGroups.forEach(
        calendarGroup => {
          if (calendarGroup.calendars) {
            this.allCalendars = this.allCalendars.concat(calendarGroup.calendars);
          }
        }
      )
    } else {
      this.allCalendars = null;
    }
  }

  public findCalendarById(calendarId: number): Calendar {
    if (this.allCalendars) {
      return this.allCalendars.find(calendar => calendar.calendarId === calendarId);
    }
    return null;
  }
}
