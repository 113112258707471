<div
(click)="onClick()" (dblclick)="onDoubleClick()"
class="wrapper {{tunifyColor == TunifyColor.BLUE ? 'blue' : (tunifyColor == TunifyColor.GREEN ? 'green' : 'orange')}} {{isSelected ? 'selected-item' : ''}}">
<!--
  <div *ngIf="useSelectionBox" class="checkbox-container">
    <tun-checkbox-v5
      class="checkbox {{showSelectionBox ? 'checkbox-force-visible' : ''}}"
      [checkBoxColor]="checkBoxColor"
      [isChecked]="isSelected"
      (isCheckedChanged)="onCheckedChange($event)"
    >
    </tun-checkbox-v5>
  </div>
-->
  <div class="play-button-container">

    <button
      class="play-button icon-button {{playEnabled ? '' : 'disable-button'}} {{!showAddButton ? 'play-button-force-visible' : ''}}"
      [matTooltip]="(isPlaying ? (isPlayActive ? 'trackOptions.tooltip.play.pause': 'trackOptions.tooltip.play.resume') : 'trackOptions.tooltip.play.start') | translate"
      (click)="onTogglePlay($event)">
      <tun-play-track *ngIf="!isPlaying || !isPlayActive" class="icon {{showSelectionBox ? '' : 'colored-icon'}}"></tun-play-track>
      <tun-pause-track-v5 *ngIf="isPlaying && isPlayActive" class="icon {{showSelectionBox ? '' : 'colored-icon'}}"></tun-pause-track-v5>
    </button>

  </div>

  <div *ngIf="showAddButton" class="add-button-container">
    <button *ngIf="!isPlaying"
      [disabled]="isAdded"
      [matTooltip]="(isAdded ? 'trackOptions.queue.isInQueue.tooltip' : 'trackOptions.queue.add') | translate"
      class="icon-button {{addEnabled ? '' : 'disable-button'}}"
      (click)="onAdd($event)">
      <!--<tun-add-track-to-queue-v5 *ngIf="!isAdded" class="icon"></tun-add-track-to-queue-v5>-->
      <tun-add-to-queue-v5 *ngIf="!isAdded" class="icon icon-add-to-queue"></tun-add-to-queue-v5 >
      <tun-checkmark-icon-v5 *ngIf="isAdded" class="icon icon-small"></tun-checkmark-icon-v5>
    </button>

    <tun-music-play-animation
      class="playAnimationContainer"
      *ngIf="isPlaying"
      [playing]="isPlayActive"
      [musicPlayAnimationColors]="playAnimationColors"
    >
    </tun-music-play-animation>
  </div>


  <div class="trackContent">
    <div class="trackTitleContainer">
      <tun-scrollable-text class="trackTitle" [text]="track.title"></tun-scrollable-text>
    </div>
    <div class="trackGroupContainer">
      <tun-scrollable-text class="trackGroup" [text]="track.group"></tun-scrollable-text>
    </div>
  </div>

  <div *ngIf="showAudioFileProperty != null" class="trackPropertyContent">
    <tun-scrollable-text class="trackProperty" [text]="trackPropertyText"></tun-scrollable-text>
  </div>

  <button
    class="icon-button"
    [matTooltip]="'trackOptions.tooltip.info' | translate"
    (click)="onOptions($event)"
  >
    <tun-track-options class="icon"></tun-track-options>
  </button>

</div>
