import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectDesktopAppRoutingModule } from './connect-desktop-app-routing-module';
import { ConnectDesktopAppViewComponent } from './connect-desktop-app-view/connect-desktop-app-view.component';
import { ConnectZoneConnectionToDesktopViewComponent } from './connect-desktop-app-view/connect-zone-connection-to-desktop-view/connect-zone-connection-to-desktop-view.component';
import { IconsModule } from '@components/icons/icons.module';
import { ComponentsV5Module } from '@components/components-v5/components-v5.module';
import { ResizeModule } from '@components/resize/resize.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollableTextComponent } from '@components/components-v5/scrollable-text/scrollable-text.component';



@NgModule({
  declarations: [
    ConnectZoneConnectionToDesktopViewComponent,
    ConnectDesktopAppViewComponent,
  ],
  imports: [
    CommonModule,
    ConnectDesktopAppRoutingModule,
    IconsModule,
    ComponentsV5Module,
    ResizeModule,
    FontAwesomeModule,
    TranslateModule,
    ScrollableTextComponent
  ]
})
export class ConnectDesktopAppModule { }
