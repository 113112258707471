import { Component, OnDestroy, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { TrackTableItem } from '@components/components-v5/track-list-view/track-list-view.component';
import { translatedDescriptionForSearchData } from '@service/search.service';
import { Subject, merge, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { LoggerService } from '@service/loggers/logger.service';
import { PlaylistService } from '@service/playlist.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { AudioFile } from '@model/audioFile';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { Playlist } from '@model/playlist';
import { transition, trigger, useAnimation } from '@angular/animations';
import { slideUpAnimation } from '@util/animations';
import { SearchState } from '../../search-music-collection-result-view/search-music-collection-result-content-view/search-music-collection-result-content-view.component';
import { SearchTextV5Service } from '@service/app-v5/search-text-v5.service';
import { AutocompletionObject, AutocompletionService } from '@service/autocompletion.service';
import { SubscriptionsService } from '@service/subscriptions.service';

@Component({
  selector: 'tun-search-text-content-view',
  templateUrl: './search-text-content-view.component.html',
  styleUrls: ['./search-text-content-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]

})
export class SearchTextContentViewComponent implements OnInit, AfterViewInit, OnDestroy{

  @Input() canReceiveFocus = false;

  @Output() back = new EventEmitter<void>();

  public get showAutoComplete():boolean{
    return this.searchFieldFocussed && this.autocompletionService.autocompletionEnabled && this.lastAutocompleteValue != null && this.lastAutocompleteValue != ""
  }
  private searchFieldFocussed = false

  public LOGGER_NAME = SearchTextContentViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public TunifyCheckBoxColor = TunifyCheckBoxColor

  public get showingPopup(){
    return this.showChoosePlaylistOverlay;
  }
  public showChoosePlaylistOverlay = false


  public get searchTitle$(){
    return this.searchTextV5Service.lastSearch$
      .pipe(
        map(
          searchData => {
            return translatedDescriptionForSearchData(this.translateService, searchData);
          }
        )
      )
  }

  //make the enum available in the html template
  public SearchState = SearchState;

  get searchState(): SearchState{
      if (this.searchTextV5Service.searching){
        return SearchState.searching;
      }else if (this.searchTextV5Service.searchError){
        return SearchState.searchError;
      }else if (this.searchTextV5Service.lastSearch != null){
        return SearchState.normal;
      }
      return SearchState.none;
  }

  get audioFileProperty$(){
    return this.zoneConfigurationService.audioFileProperty$
  }

  public get startTrackEnabled$(){
    return this.subscriptionsService.accessRights$
      .pipe(
        map(
          (accessRights => {
            return accessRights == null || accessRights.startTrack
          })
        )
      )
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private searchTextV5Service: SearchTextV5Service,
    private translateService: TranslateService,
    private zoneConfigurationService: ZoneConfigurationService,
    private musicManipulationService: MusicManipulationService,
    private queueService: QueueService,
    private loggerService: LoggerService,
    private playlistService: PlaylistService,
    private snackBar: MatSnackBar,
    private trackOverlayService: TrackOverlayService,
    private subscriptionsService: SubscriptionsService,
    private autocompletionService: AutocompletionService
  ) {

  }

  private tracksChanged$ = new Subject<void>();
  ngOnInit(): void {
    this.tracks$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      () => {
        this.tracksChanged$.next();
      }
    )
  }

  ngAfterViewInit(): void {

  }

  public get hasTracks$(){
    return this.searchTextV5Service.searchResult$
      .pipe(
        map(tracks => {
          return tracks && tracks.length > 0
        })
      )
  }

  public get tracks$(){
    return this.searchTextV5Service.searchResult$;
  }



  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }r

  onBack(){
    this.back.emit();
  }

  onSearch(value: string) {
    // Start a search
    this.searchTextV5Service.searchOnText(value);
  }

  onSelectAutocomplete(autocompleteObject: AutocompletionObject){
    this.searchTextV5Service.searchOnAutocompletion(autocompleteObject);
  }

  onPlayAll(){
    if (this.searchTextV5Service.searchResult){
      if (this.searchTextV5Service.searchResult.length > 0){
        const tracksToQueue: AudioFile[] = [];
        this.searchTextV5Service.searchResult.forEach(track => tracksToQueue.push(createClassObjectForAudioFile(track)));

        const trackToPlay = tracksToQueue.shift()
        this.musicManipulationService.playAudioFile(trackToPlay);

        if (tracksToQueue.length > 0){
          this.queueService.clearQueue();
          this.queueService.addAudioFilesToQueue(tracksToQueue);
        }

        this.checkedTracks = [];
      }
    }
  }

  /* Handler for selection header */



  onSearchFieldFocus(focus: boolean){
    this.searchFieldFocussed = focus
  }

  public lastAutocompleteValue: string = "";
  onAutocomplete(value: string){
    this.lastAutocompleteValue = value
  }

  public checkedTracks : AudioFile[] = [];
  onCheckedTracksChanged(checkedTracks: AudioFile[]){
    this.checkedTracks = checkedTracks;
  }
  onAddTracksToPlaylist(){
    this.showChoosePlaylistOverlay = true
  }

  // Handlers for track items

  onPlay(track: AudioFile){
    this.musicManipulationService.playAudioFile(track);
  }

  public onShowOptions(track: AudioFile){
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = new TrackInfoOverlayData(track, new TrackInfoContext(TrackInfoContextMode.search));
  }


  /* Select playlist popup */
  onCloseSelectPlaylist(playlist: Playlist){
    if (playlist && this.checkedTracks.length > 0){
      this.playlistService.addAudioFileToPlaylist(playlist, this.checkedTracks)
      .pipe(
        takeUntil(
          merge(
            this.tracksChanged$,
            this.destroyed$
          )
        )
      ).subscribe(
        (asyncStatus) => {
          if (asyncStatus == AsyncStatus.COMPLETED){
            this.checkedTracks = [];
              const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToPlaylist.succes'), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });
          }
        }
      );
    }
    this.showChoosePlaylistOverlay = false;
  }


  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;
    this.showChoosePlaylistOverlay = false;
  }

}
