import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { TunifyColor } from '../../../model/enums/tunifyColor.enum';
import { AudioFileProperty, formatTrackProperty } from '@model/enums/audioFileProperty';
import { CheckBoxColor } from '@model/fieldModels/checkboxColor';
import { TunifyCheckBoxColor } from '../checkbox-v5/checkbox-v5.component';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';

@Component({
  selector: 'tun-track-view',
  templateUrl: './track-view.component.html',
  styleUrls: ['./track-view.component.scss']
})
export class TrackViewComponent {

  @Input() track: AudioFile
  @Input() tunifyColor: TunifyColor

  @Input() playEnabled = true;  //when the user can perform this action (subscription check)
  @Input() fixedPlayButton = false; // when the play button should always be visible (not only on hover)
  @Input() isPlaying = false;
  @Input() isPlayActive = false;
  @Input() playAnimationColors : MusicPlayAnimationColors = new MusicPlayAnimationColors().selectedColor("white").unselectedColor("white");

  @Input() useSelectionBox: boolean = false;
  @Input() showSelectionBox: boolean = false;
  @Input() isSelected: boolean = false;

  //@Input() showAddToQueue = false;
  //@Input() isInQueue = false;

  @Input() showAddButton = false;
  @Input() addEnabled = true; //when the user can perform this action (subscription check)
  @Input() addActionIsQueue = true; //for our tooltip
  @Input() isAdded = false;

  @Input() showAudioFileProperty: AudioFileProperty

  @Output() play = new EventEmitter<void>()
  @Output() resume = new EventEmitter<void>()
  @Output() pause = new EventEmitter<void>()

  @Output() showOptions = new EventEmitter<void>()
  @Output() checkedChanged = new EventEmitter()
  @Output() addChanged = new EventEmitter()

  public TunifyColor = TunifyColor

  public get checkBoxColor(){
    if (this.tunifyColor == TunifyColor.ORANGE){
      return TunifyCheckBoxColor.ORANGE
    }else if (this.tunifyColor == TunifyColor.BLUE){
      return TunifyCheckBoxColor.BLUE
    }else if (this.tunifyColor == TunifyColor.GREEN){
      return TunifyCheckBoxColor.GREEN
    }
    return TunifyCheckBoxColor.WHITE
  }

  public get trackPropertyText(){
    if (this.track && this.showAudioFileProperty){
      return formatTrackProperty(this.track, this.showAudioFileProperty);
    }
    return ""
  }

  public onTogglePlay(event: MouseEvent){
    event.stopPropagation();
    if (this.isPlaying && this.isPlayActive){
      this.pause.emit();
    }else if (this.isPlaying){
      this.resume.emit();
    }else{
      this.play.emit();
    }
  }

  public onOptions(event: MouseEvent){
    event.stopPropagation();
    this.showOptions.emit();
  }

  public onAdd(event: MouseEvent){
    event.stopPropagation();
    this.addChanged.emit(true);
  }

  public onCheckedChange(value:boolean){
    this.checkedChanged.emit(value);
  }

  public onClick(){
    this.checkedChanged.emit(!this.isSelected);
  }

  public onDoubleClick(){
    this.play.emit();
  }


}
