<div class="wrapper">

  <!--
  <div class="imageWrapper">
    <img class="backgroundImage" [src]="imageUrl$ | async">
    <div class="gradientLayer"></div>
  </div>
  -->


  <div class="contentWrapper {{showingPopup ? 'blurredContent' : ''}}">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button
          [matTooltip]="'playlist.back.tooltip' | translate"
          class="icon-button header-icon-button"
          (click)="onBack()"
        >
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <button
          [disabled]="!hasTracks"
          [matTooltip]="'trackOptions.list.playAll' | translate"
          class="icon-button first-title-item {{checkedTracks.length > 0 ? 'no-focus' : ''}}"
          (click)="onPlayAll()"
        >
          <tun-play-icon-v5 class="icon colored-icon"></tun-play-icon-v5>
        </button>

        <div class="title">
          {{title}}
        </div>


        <button class="icon-button"
          [matTooltip]="(canEditProperties ? 'playlist.extraAction.tooltip' : 'playlist.extraAction.notAvailable.tooltip') | translate"
          [disabled]="!canEditProperties"
          [matMenuTriggerFor]="menu"
        >
          <tun-track-options class="icon"></tun-track-options>
        </button>
        <mat-menu #menu="matMenu">
          <button class="option-button" (click)="onChangeName()">
            <tun-edit-icon class="icon option-button-icon"></tun-edit-icon>
            <span translate>playlist.extraAction.changeName</span>
          </button>
          <button class="option-button" (click)="onDelete()">
            <tun-delete-icon-v5 class="icon option-button-icon"></tun-delete-icon-v5>
            <span translate>playlist.extraAction.delete</span>
          </button>
        </mat-menu>



      </div>

      <div class="remainingSpace"></div>

    </div>

    <ng-container *ngIf="showPlaylist">

      <!-- Special playlist states indicator -->

      <div
        *ngIf="playlistState != PlaylistState.normal"
        [ngSwitch]="playlistState"
        class="feedback-container"
      >
        <div
          *ngSwitchCase="PlaylistState.loadError"
          class="error-feedback-container"
        >
          <p>
            {{'calendar.contentPanel.load.error' | translate }}
          </p>
          <button (click)="retryClick()" class="retryButton">
            <p>
              {{'general.tryAgain' | translate }}
            </p>
          </button>
        </div>

        <div
          *ngSwitchCase="PlaylistState.empty"
          class="status-feedback-container"
        >
          <tun-menu-playlist-icon-v5 class="feedback-icon"></tun-menu-playlist-icon-v5>
          <p translate>playlist.status.empty</p>

          <button
            class="add-button" (click)="onAddSongs()"
          >
            <div class="add-icon-container">
              <tun-plus-icon-v5 class="icon icon-add-button"></tun-plus-icon-v5>
            </div>
            {{'playlist.button.addSongs' | translate}}
          </button>
        </div>

        <ng-container *ngSwitchCase="PlaylistState.loading">
          <tun-loader-v5 class="loader"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="PlaylistState.creating">
          <tun-loader-v5 text="playlist.status.creating"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="PlaylistState.deleting">
          <tun-loader-v5 text="playlist.status.deleting"></tun-loader-v5>
        </ng-container>

        <div
          *ngSwitchCase="PlaylistState.deleted"
          class="error-feedback-container"
        >
          <p translate>playlist.status.deleted</p>
          <button (click)="onBack()" class="retryButton">
            <p translate>playlist.status.deleted.close</p>
          </button>
        </div>
      </div>



      <div
        *ngIf="playlistState == PlaylistState.normal"
        class="amount-title"
      >
        <button
          class="add-button" (click)="onAddSongs()"
        >
          <div class="add-icon-container">
            <tun-plus-icon-v5 class="icon icon-add-button"></tun-plus-icon-v5>
          </div>
          {{'playlist.button.addSongs' | translate}}
        </button>
      </div>

      <tun-selectable-track-list-view
        *ngIf="playlistState == PlaylistState.normal"
        class="trackTable"
        [tracks]="tracks"
        [checkedTracks]="checkedTracks"
        [audioFileProperty]="audioFileProperty$ | async"
        allowDragAndDrop="true"
        (checkedTracksChanged)="onCheckedTracksChanged($event)"
        (addTracksToPlaylist)="onAddTracksToPlaylist($event)"
        (showOptions)="onShowOptions($event)"
        (trackIndexEvent)="onTrackIndexEvent($event)"
      >
      </tun-selectable-track-list-view>


    </ng-container>


  </div>

  <!-- Popup: choose playlist-->
  <div #choosePlaylistPopupWrapper *ngIf="showChoosePlaylistOverlay" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
    <tun-overlay-select-playlist
      class="select-playlist-overlay"
      confirmButtonTranslateableText="playlist.addTrack.popup.button.commit"
      (closeWithPlaylist)="onCloseSelectPlaylist($event)"
    >
    </tun-overlay-select-playlist>
  </div>


</div>

