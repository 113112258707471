import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { BehaviorSubject, combineLatest, map, merge, Subject, takeUntil } from 'rxjs';
import { TrackTableItem } from '../track-list-view/track-list-view.component';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { TranslateService } from '@ngx-translate/core';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { LoggerService } from '@service/loggers/logger.service';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TunifyCheckBoxColor } from '../checkbox-v5/checkbox-v5.component';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualForOf } from '@angular/cdk/scrolling';
import { TrackIndexAction, TrackIndexEvent } from '@model/events/trackIndexEvent';

  /**
   * BUG SEMI FIX:
   *
   * Virtual scrolling and autoscroll during drag&drop does not work
   *
   * Strategy:
   * When dragAndDrop is enabled:
   * Less than 100 items: no virtual scrolling and allow autoscroll
   * 100 items+: virtual scrolling and disable autoscroll
   *
   * When dragAndDrop is disabled:
   * Always enable virtual scrolling
   *
   * Virtual scrolling is disabled when the itemSize is not set
   */


@Component({
  selector: 'tun-selectable-track-list-view',
  templateUrl: './selectable-track-list-view.component.html',
  styleUrl: './selectable-track-list-view.component.scss'
})
export class SelectableTrackListViewComponent implements OnChanges, OnDestroy{

  public LOGGER_NAME = SelectableTrackListViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public TunifyCheckBoxColor = TunifyCheckBoxColor;
  public TunifyColor = TunifyColor;

  @Input() tracks: AudioFile[] = [];
  @Input() checkedTracks: AudioFile[] = [];

  @Input() allowDragAndDrop = false;

  @Input() audioFileProperty = AudioFileProperty.YEAR;

  @Output() addTracksToPlaylist = new EventEmitter<AudioFile[]>();
  @Output() checkedTracksChanged = new EventEmitter<AudioFile[]>();

  @Output() showOptions = new EventEmitter<AudioFile>();

  @Output() trackIndexEvent = new EventEmitter<TrackIndexEvent>();

  constructor(
    private translateService: TranslateService,
      private musicManipulationService: MusicManipulationService,
      private queueService: QueueService,
      private loggerService: LoggerService,
      private subscriptionsService: SubscriptionsService,
      private playingItemService: PlayingItemService,
      private snackBar: MatSnackBar,
      private appV5StateService: AppV5StateService,
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes['tracks']) {
        if (this.tracks){
          this.trackTableItems = this.tracks.map(track => new TrackTableItem(track))
        }else{
          this.trackTableItems = [];
        }
        this.checkedTracks = [];
        this.updateAllComplete();
        this.adjustDisableAutoScrollSubject();
      }else if (changes['allowDragAndDrop']) {
        this.adjustDisableAutoScrollSubject();
      }else if (changes['checkedTracks']) {
        this.updateAllComplete();
      }
    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy() {
      this.trackTableItemsChanged$.complete();

      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;


    }


  /* data for trackListView */
    private trackTableItemsChanged$ = new Subject<void>();
    private _trackTableItems: TrackTableItem[];
    public set trackTableItems(value: TrackTableItem[]){
      this._trackTableItems = value;
      this.trackTableItemsChanged$.next();
    }
    public get trackTableItems(){
      return this._trackTableItems;
    }

  /* data properties */
    public get trackItemSize$(){
        return this.appV5StateService.pixelsFor1Rem$
        .pipe(
          map(px => {
            return 3 * px
          })
        )
      }

      public get startTrackEnabled$(){
        return this.subscriptionsService.accessRights$
          .pipe(
            map(
              (accessRights => {
                return accessRights == null || accessRights.startTrack
              })
            )
          )
      }

  //Track is playing
  isTrackPlaying(track: AudioFile){
    return this.playingItemService.isTrackPlaying(track);
  }

  isRealyPlaying(){
    return this.playingItemService.isPlaying;
  }

  //Track in queue
  public onAddChanged(value:boolean, track: AudioFile){
    if (value){
      this.queueService.addAudioFilesToQueue([track]);
    }else{
      //Remove is not supported
    }
  }

  public isTrackAdded(track: AudioFile){
    return this.queueService.queue && this.queueService.queue.filter(t => t.id == track.id).length > 0
  }

    /* tooltips */
    get addMultiToPlaylistTooltip(){
      if (this.checkedTracks.length == 0){
        return this.translateService.instant('trackOptions.tooltip.disabled.selectSong')
      }else if (this.checkedTracks.length == 1){
        return this.translateService.instant('trackOptions.tooltip.one.addToPlaylist')
      }else{
        return this.translateService.instant('trackOptions.tooltip.multi.addToPlaylist').replace("{0}", this.checkedTracks.length.toString())
      }
    }

    get addMultiToQueueTooltip(){
      if (this.checkedTracks.length == 0){
        return this.translateService.instant('trackOptions.tooltip.disabled.selectSong')
      }else if (this.checkedTracks.length == 1){
        return this.translateService.instant('trackOptions.tooltip.one.addToQueue')
      }else{
        return this.translateService.instant('trackOptions.tooltip.multi.addToQueue').replace("{0}", this.checkedTracks.length.toString())
      }
    }

  /* Handler for selection header */
    get showSelectionBox(){
      return this.checkedTracks.length > 0
    }

    /* Handlers for track item */
      public onPlay(track: AudioFile){
        this.musicManipulationService.playAudioFile(track);
      }

      public onResume(){
        this.musicManipulationService.play();
      }

      public onPause(){
        this.musicManipulationService.pause();
      }

      public onShowOptions(track: AudioFile){
        this.showOptions.emit(track);
      }

      public onCheckedChanged(value:boolean, track: AudioFile){
        if (value){
          //add to checkedTracks
          if (this.checkedTracks.filter(t => t == track).length == 0){
            this.checkedTracks.push(track);
            this.triggerCheckedTracksChanged()
          }else{
            this.loggerService.error(this.LOGGER_NAME, "onCheckedChanged", "checked a track that was already checked");
          }
        }else{
          if (this.checkedTracks.filter(t => t == track).length > 0){
            this.checkedTracks = this.checkedTracks.filter(t => t != track);
            this.triggerCheckedTracksChanged()
          }else{
            this.loggerService.error(this.LOGGER_NAME, "onCheckedChanged", "un-checked a track that was already un-checked");
          }
        }
      }

      private triggerCheckedTracksChanged(){
        this.updateAllComplete()
        this.checkedTracksChanged.emit(this.checkedTracks);
      }

      public isTrackChecked(track: AudioFile){
        return this.checkedTracks.filter(t => t == track).length > 0
      }

    get selectTitle(){
      if (this.checkedTracks.length == 0){
        return this.translateService.instant('search.result.select.all')
      }else if (this.checkedTracks.length == 1){
        return this.translateService.instant('search.result.select.amount.one')
      }else{
        return this.translateService.instant('search.result.select.amount.many').replace("{0}", this.checkedTracks.length);
      }
    }

    allComplete: boolean = false;

    updateAllComplete() {
      this.allComplete = this.trackTableItems.every(trackTableItem => this.checkedTracks.includes(trackTableItem.track))
    }

    someComplete(): boolean {
      if (this.trackTableItems == null) {
        return false;
      }
      return !this.allComplete && this.checkedTracks.length > 0;
    }

    setAll(completed: boolean) {
      this.allComplete = completed;
      if (this.allComplete){
        this.checkedTracks = this.trackTableItems.map(trackTableItem => trackTableItem.track);
      }else{
        this.checkedTracks = [];
      }
      this.checkedTracksChanged.emit(this.checkedTracks);
    }

  /* Handler for selected tracks actions */
    onAddSelectedToQueue(){
      this.musicManipulationService.addAudioFilesToQueue(this.checkedTracks)
      .pipe(
        takeUntil(
          merge(
            this.trackTableItemsChanged$,
            this.destroyed$
          )
        )
      ).subscribe(
        (asyncStatus) => {
          if (asyncStatus == AsyncStatus.COMPLETED){
            //deselect all tracks
            this.checkedTracks = [];
            this.triggerCheckedTracksChanged();

              const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });

          }
        }
      );


    }

  onAddSelectedToPlaylist(){
    this.addTracksToPlaylist.emit(this.checkedTracks);
  }

  /* Drag & drop */

  private range: ListRange;

  private virtualForOfChanged$ = new Subject<void>();
  private _virtualForOf: CdkVirtualForOf<TrackTableItem[]>;



  @ViewChild(CdkVirtualForOf) set virtualForOf(virtualForOf: CdkVirtualForOf<TrackTableItem[]>) {
    this.virtualForOfChanged$.next();
    this._virtualForOf = virtualForOf;

    if(this._virtualForOf) {
        //console.log("virtualForOf set")
      this._virtualForOf.viewChange
      .pipe(
        takeUntil(
          merge(
            this.virtualForOfChanged$,
            this.destroyed$
          )
        )
      )
      .subscribe((range: ListRange) => {
        this.range = range
        //console.log("range set: " + range.start + " to " + range.end)
      });
    }
  }

  //virtual scrolling can be disabled by providing no value for itemSize
  get virtualScrollingItemSize$(){
    return combineLatest([this.trackItemSize$, this.disableAutoScroll$])
    .pipe(
      map(
        ([itemSize, disableAutoScroll]) => {
          if (disableAutoScroll){
            return itemSize;
          }
          return "corrupt";
        }
      )
    )
  }

   public get disableAutoScroll$(){
        return this.disableAutoScrollSubject$.asObservable();
      }
      private disableAutoScrollSubject$ = new BehaviorSubject<boolean>(false);
      private adjustDisableAutoScrollSubject(){
        const newValue = this.allowDragAndDrop && this.tracks && this.tracks.length > 100;
        if (newValue != this.disableAutoScrollSubject$.value){
          this.disableAutoScrollSubject$.next(newValue);
        }

      }

    public drop(event: CdkDragDrop<TrackTableItem[]>) {

      if( !event.isPointerOverContainer) {
        return
      }

      const PREV_IND_WITH_OFFSET: number = (this.range ? this.range.start : 0) + event.previousIndex;
      const CUR_IND_WITH_OFFSET: number = (this.range ? this.range.start : 0) + event.currentIndex;

      if (event.item.data instanceof TrackTableItem){
        const track = event.item.data.track;

        //move from inside
        if (event.previousContainer == event.container){

          //move to some position -> do nothing
          if (PREV_IND_WITH_OFFSET == CUR_IND_WITH_OFFSET){
            return
          }
          this.trackIndexEvent.emit(new TrackIndexEvent(TrackIndexAction.MOVE_TO_INDEX, track, CUR_IND_WITH_OFFSET));
          //this.playlistService.moveAudioFileInPlaylist(this.showPlaylist, [track], CUR_IND_WITH_OFFSET);
        }else{
          this.trackIndexEvent.emit(new TrackIndexEvent(TrackIndexAction.ADD_AT_INDEX, track, CUR_IND_WITH_OFFSET));
          //this.playlistService.addAudioFileToPlaylist(this.showPlaylist, [track], CUR_IND_WITH_OFFSET);
        }

      }
    }
}
