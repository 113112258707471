import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { CalendarGroupService } from '@service/calendar-group.service';
import { CalendarService } from '@service/calendar.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-calendar-groups-view',
  templateUrl: './calendar-groups-view.component.html',
  styleUrls: ['./calendar-groups-view.component.scss']
})
export class CalendarGroupsViewComponent implements OnInit, OnDestroy {

  public get calendarGroupsLoading$(){
    return this.calendarGroupService.loading$
  }

  public get calendarGroupsLoadingError$(){
    return this.calendarGroupService.loadingError$
  }

  public get calendarGroups$(){
    return this.calendarGroupService.calendarGroups$
  }

  public get showCustomCalendars$(){
    return this.customCalendars$
      .pipe(map(customCalendars => customCalendars != null && customCalendars.length > 0))
  }

  public get showCreateCustomCalendarButton$(){
    return combineLatest([this.accessToCustomCalendars$, this.showCustomCalendars$, this.calendarService.loading$])
      .pipe(
        map(([accessToCustomCalendars, showCustomCalendars, loading]) => accessToCustomCalendars && !showCustomCalendars && !loading && this.calendarService.loadedAfterStartup)
      );
  }
/*
  public get showCustomCalendars$(){
    return combineLatest([this.subscriptionsService.accessRights$, this.customCalendars$])
      .pipe(map(([accessRights, customCalendars]) => accessRights != null && accessRights.customCalendars && customCalendars != null && customCalendars.length > 0))
  }
*/

  public get customCalendars$(){
    return this.calendarService.customCalendars$
  }

  public get accessToCustomCalendars$(){
    return  this.subscriptionsService.accessRights$
      .pipe(
        map(
          accessRights => {
            return accessRights == null || accessRights.customCalendars
          }
        )
      )
  }

  constructor(
    private calendarGroupService: CalendarGroupService,
    private subscriptionsService: SubscriptionsService,
    private calendarService: CalendarService,
    private appV5StateService: AppV5StateService,
    private zoneConfigurationService: ZoneConfigurationService
  ) {

  }

  ngOnInit(): void {
      this.calendarGroupService.loadDataIfNeeded();
      this.calendarService.loadCustomCalendarsIfNeeded();
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onRetry(){
    this.calendarService.loadCustomCalendarsIfNeeded();
    this.calendarGroupService.loadCalendarGroups();
  }

  public onAddCalendar(){
    this.appV5StateService.showCreateCalendar = true;
  }
}
