<div class="wrapper">


  <div class="select-title">

        <div class="title-spacer"></div>

        <button
          [disabled]="checkedTracks.length == 0"
          [matTooltip]="addMultiToQueueTooltip"
          class="icon-button colored-action-button"
          (click)="onAddSelectedToQueue()">
          <tun-add-to-queue-v5 class="icon"></tun-add-to-queue-v5>
        </button>

        <button
          [disabled]="checkedTracks.length == 0"
          [matTooltip]="addMultiToPlaylistTooltip"
          class="icon-button colored-action-button"
          (click)="onAddSelectedToPlaylist()"
        >
          <tun-add-to-playlist-v5 class="icon"></tun-add-to-playlist-v5>
        </button>


        <!--<div class="title-spacer"></div>-->

        <div class="select-front">
          <tun-checkbox-v5
          class="select-all-checkbox"
          [title]="selectTitle"
          coloredTitleOnCheck="true"
          [checkBoxColor]="TunifyCheckBoxColor.ORANGE"
          [isChecked]="allComplete"
          [indeterminate]="someComplete()"
          (isCheckedChanged)="setAll($event)"
        >
        </tun-checkbox-v5>

        <button *ngIf="checkedTracks.length != 0" class="unselect-link-button" (click)="setAll(false)">
          <p translate>
            search.result.deselect.all
          </p>
        </button>

        </div>



      </div>

      <!-- normal content -->
      <cdk-virtual-scroll-viewport
        #scrollViewport
        class="trackTable"
        cdkDropList
        [cdkDropListData]="trackTableItems"
        [cdkDropListConnectedTo]="['queueHeader', 'queue']"
        [cdkDropListSortingDisabled]="!allowDragAndDrop"
        [itemSize]="virtualScrollingItemSize$ | async"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListAutoScrollDisabled]="disableAutoScroll$ | async"
      >
        <!--
        ---- templateCacheSize: 0
        ---- We can not use a cache to support drag & drop through the angular cdk
        ---- Drag & drop caches items indexes, this cache is not re-calculated if an item moves from on-screen to the cache
        -->

        <ng-container>
            <tun-track-view
              *cdkVirtualFor="
              let trackTableItem of trackTableItems;
              let i = index;
              templateCacheSize: 0
              "
              class="trackItem"
              [track]="trackTableItem.track"


              [playEnabled]="startTrackEnabled$ | async"
              [isPlaying]="isTrackPlaying(trackTableItem.track)"
              [isPlayActive]="isRealyPlaying()"
              (play)="onPlay(trackTableItem.track)"
              (resume)="onResume()"
              (pause)="onPause()"

              showAddButton="true"
              [isAdded]="isTrackAdded(trackTableItem.track)"
              (addChanged)="onAddChanged($event, trackTableItem.track)"

              [useSelectionBox]="true"
              [isSelected]="isTrackChecked(trackTableItem.track)"
              (checkedChanged)="onCheckedChanged($event, trackTableItem.track)"
              [showAudioFileProperty]="audioFileProperty"
              [tunifyColor]="TunifyColor.ORANGE"

              (showOptions)="onShowOptions(trackTableItem.track)"
              [style.height.px]="trackItemSize$ | async"
              cdkDrag
              [cdkDragData]="trackTableItem"
            >
            </tun-track-view>

        </ng-container>

      </cdk-virtual-scroll-viewport>
</div>
