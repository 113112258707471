import { Component, OnDestroy, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { TrackTableItem } from '@components/components-v5/track-list-view/track-list-view.component';
import { translatedDescriptionForSearchData } from '@service/search.service';
import { Subject, merge, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { LoggerService } from '@service/loggers/logger.service';
import { PlaylistService } from '@service/playlist.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { AudioFile } from '@model/audioFile';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { Playlist } from '@model/playlist';
import { transition, trigger, useAnimation } from '@angular/animations';
import { slideUpAnimation } from '@util/animations';
import { SearchTextV5Service } from '@service/app-v5/search-text-v5.service';
import { AutocompletionObject } from '@service/autocompletion.service';
import { SearchState } from '@view/player-v5/main-content/search-panel/search-music-collection-result-view/search-music-collection-result-content-view/search-music-collection-result-content-view.component';
import { SubscriptionsService } from '@service/subscriptions.service';

@Component({
  selector: 'tun-search-to-add-content-view',
  templateUrl: './search-to-add-content-view.component.html',
  styleUrls: ['./search-to-add-content-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})
export class SearchToAddContentViewComponent implements OnInit, AfterViewInit, OnDestroy{

  @Input() playlist: Playlist;

  @Input() canReceiveFocus = false;

  @Output() back = new EventEmitter<void>();

  public get showAutoComplete():boolean{
    return this.searchFieldFocussed && this.lastAutocompleteValue != null && this.lastAutocompleteValue != ""
  }
  private searchFieldFocussed = false


  public panelTitleForPlaylist(playlist: Playlist){
    if (playlist){
      return this.translateService.instant('playlist.addTrack.title').replace("{0}", playlist.title);
    }
    return "";
  }


  public LOGGER_NAME = SearchToAddContentViewComponent.name;

  public TunifyCheckBoxColor = TunifyCheckBoxColor


  public get trackItemSize$(){
    return this.appV5StateService.pixelsFor1Rem$
    .pipe(
      map(px => {
        return 3 * px
      })
    )
  }

  private trackTableItemsChanged$ = new Subject<void>();
  private _trackTableItems: TrackTableItem[] = [];
  public set trackTableItems(value: TrackTableItem[]){
    this._trackTableItems = value;
    this.trackTableItemsChanged$.next();
  }
  public get trackTableItems(){
    return this._trackTableItems;
  }

  public get searchTitle$(){
    return this.searchTextV5Service.lastSearch$
      .pipe(
        map(
          searchData => {
            return translatedDescriptionForSearchData(this.translateService, searchData);
          }
        )
      )
  }

  //make the enum available in the html template
  public SearchState = SearchState;

  get searchState(): SearchState{
      if (this.searchTextV5Service.searching){
        return SearchState.searching;
      }else if (this.searchTextV5Service.searchError){
        return SearchState.searchError;
      }else if (this.searchTextV5Service.lastSearch != null){
        return SearchState.normal;
      }
      return SearchState.none;
  }

  get audioFileProperty$(){
    return this.zoneConfigurationService.audioFileProperty$
  }



  constructor(
    private appV5StateService: AppV5StateService,
    private searchTextV5Service: SearchTextV5Service,
    private translateService: TranslateService,
    private zoneConfigurationService: ZoneConfigurationService,
    private subscriptionsService: SubscriptionsService,
    private loggerService: LoggerService,
    private playlistService: PlaylistService,
    private snackBar: MatSnackBar,
    private trackOverlayService: TrackOverlayService
  ) {

  }

  ngOnInit(): void {
    this.initTrackTableItems();
  }

  ngAfterViewInit(): void {

  }

  private initTrackTableItems(){
    this.searchTextV5Service.searchResult$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (tracks)=>{
        if (tracks){
          this.trackTableItems = tracks.map(track => new TrackTableItem(track))
        }else{
          this.trackTableItems = [];
        }
      }
    )
  }


  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.trackTableItemsChanged$.complete();

    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }r

  onBack(){
    this.back.emit();
  }

  onSearch(value: string) {
    // Start a search
    this.searchTextV5Service.searchOnText(value);
  }

  onSelectAutocomplete(autocompleteObject: AutocompletionObject){
    this.searchTextV5Service.searchOnAutocompletion(autocompleteObject);
  }






  onSearchFieldFocus(focus: boolean){
    this.searchFieldFocussed = focus
  }

  public lastAutocompleteValue: string = "";
  onAutocomplete(value: string){
    this.lastAutocompleteValue = value
  }

  // Handlers for track items


  public onShowOptions(track: AudioFile){
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = new TrackInfoOverlayData(track, new TrackInfoContext(TrackInfoContextMode.search));
  }

  public onAddChanged(value:boolean, track: AudioFile){
    if (value){
      //add to playlist
      this.playlistService.addAudioFileToPlaylist(this.playlist, [track])
    }else{
      if (this.playlist.audioFiles){
        //find the first object
        const tracksToRemove = this.playlist.audioFiles.filter(t => t.id == track.id);

        //remove from playlist
        this.playlistService.deleteAudioFileInPlaylist(this.playlist, tracksToRemove);
      }
    }
  }

  public isTrackAdded(track: AudioFile){
    return this.playlist && this.playlist.audioFiles && this.playlist.audioFiles.filter(t => t.id == track.id).length > 0
  }




}
