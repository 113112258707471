import { LoggerService } from "@service/loggers/logger.service";
import { Intercom } from "@supy-io/ngx-intercom";
import { Subject, takeUntil, timer } from "rxjs";

interface IntercomDTO {
  player_version?: string;
  desktopApp_last_seen_at?: number;
}

export class IntercomDataManager {


  private readonly LOGGER_CLASSNAME = IntercomDataManager.name;

  private intercom: Intercom;
  private loggerService: LoggerService;

  public playerVersion: string;
  public isDesktopApp: boolean = false;

  private _pingIntervalInSeconds = undefined;
  public pingIntervalChanged$ = new Subject();
  public set pingIntervalInSeconds(value: number) {
    if (this._pingIntervalInSeconds != value) {
      this._pingIntervalInSeconds = value;
      this.loggerService.debug(this.LOGGER_CLASSNAME, "pingIntervalInSeconds", "new value: " + value);

      this.pingIntervalChanged$.next(value);
      this.startPingIfNeeded();
    }
  }
  public get pingIntervalInSeconds(): number {
    return this._pingIntervalInSeconds;
  }

  constructor(
    intercom: Intercom,
    loggerService: LoggerService
  ) {
    this.intercom = intercom;
    this.loggerService = loggerService;
  }

  private sharePlayerVersion = false;
  private shareIsDesktopApp = false;
  public shareAllData() {
    this.sharePlayerVersion = true;
    this.shareIsDesktopApp = true;
    this.shareData();
  }

  private shareData() {
    const intercomDTO: IntercomDTO = {};
    if (this.sharePlayerVersion && this.playerVersion != null) {
      intercomDTO.player_version = this.playerVersion;
      this.sharePlayerVersion = false;
    }
    if (this.shareIsDesktopApp && this.isDesktopApp) {
      //Unix timestamp in seconds (this is the format that intercom expects)
      intercomDTO.desktopApp_last_seen_at = Math.floor(
        new Date().getTime() / 1000
      );
      this.shareIsDesktopApp = false;
    }
    this.intercom.update(intercomDTO);
  }

  private startPingIfNeeded() {
    if (this.pingIntervalInSeconds != undefined) {
      timer(this.pingIntervalInSeconds * 1000, this.pingIntervalInSeconds * 1000)
        .pipe(
          takeUntil(
            this.pingIntervalChanged$
          )
        )
        .subscribe(() => {
          this.pingIntercom();
        });
    }
  }

  private pingIntercom() {
    this.shareIsDesktopApp = true;
    this.shareData();
  }

}
