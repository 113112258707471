import { Injectable, OnDestroy } from '@angular/core';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppService } from '../../app/app.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { LoggerService } from '../../loggers/logger.service';
import { AppVersionService } from '@service/app-version.service';
import { Intercom } from '@supy-io/ngx-intercom';
import { IntercomConnectedZoneManager } from './intercom-connected-zone-manager';
import { IntercomDataManager } from './intercom-data-manager';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';

@Injectable({
  providedIn: 'root'
})
export class IntercomService implements OnDestroy {

  //for production
  private readonly INTERCOM_PING_INTERVAL_SECONDS = 300; //every 5 minutes

  //for testing -> fast ping
  //private readonly INTERCOM_PING_INTERVAL_SECONDS = 60;
  //private readonly INTERCOM_PING_INTERVAL_SECONDS = 30;


  private LOGGER_CLASSNAME = IntercomService.name;

  private unreadMessagesSubject = new BehaviorSubject<number>(0);
  public unreadMessages$ = this.unreadMessagesSubject.asObservable();

  private intercomConnectedZoneManager : IntercomConnectedZoneManager;
  private intercomDataManager: IntercomDataManager;

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private zoneConfigurationService:ZoneConfigurationService,
    private intercom: Intercom,
    private appService: AppService,
    private loggerService: LoggerService,
    private appVersionService: AppVersionService
  ) {
    if (environment.enableIntercom){

      this.intercomConnectedZoneManager = new IntercomConnectedZoneManager(this.intercom, this.loggerService);
      this.intercomDataManager = new IntercomDataManager(this.intercom, this.loggerService);

      this.intercomDataManager.isDesktopApp = isDesktopApp(); //this property does not changes in time
      this.intercomDataManager.pingIntervalInSeconds = this.INTERCOM_PING_INTERVAL_SECONDS;

      this.appService.heightForBottomBar$
      .pipe(
        map(x => x + 10),
        takeUntil(this.destroyed$)
      ).subscribe(
        (vPadding) => {
          this.intercomConnectedZoneManager.verticalPadding = vPadding;
        }
      )

      //connect intercom to the active zoneConnection, once we have a userHash
      combineLatest(
        [
          this.zoneConnectionsService.activeZoneConnection$,
          this.zoneConfigurationService.intercomUserHash$
        ]
      ).pipe(
        takeUntil(this.destroyed$)
      ).subscribe(([activeZoneConnection, intercomUserHash]) => {
        if (activeZoneConnection != null && intercomUserHash != null){
          this.intercomConnectedZoneManager.connectZoneToIntercom({ zoneId: '' + activeZoneConnection.zoneId, intercomUserHash: intercomUserHash });
        }else{
          this.intercomConnectedZoneManager.connectZoneToIntercom(null);
        }
      });

      //Share properties to intercom once the connected zone changes
      this.intercomConnectedZoneManager.currentConnectedZoneId$
      .pipe(
        delay(500), //We use a small delay to make sure the intercom connection has processed the user_id
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.intercomDataManager.shareAllData();
        }
      );

      //there is no unhook method for this. That is why we pass the unread count first to our own observable.
      this.intercom.onUnreadCountChange(count => {
        if (this.destroyed$ != null){ //update our value for as long as we are not destroyed
          this.unreadMessagesSubject.next(count);
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, 'unreadCountChanged', 'but the service is already destroyed. We should somehow try to unhook the function with a reference to this');
        }
      });


      this.appVersionService.version$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.intercomDataManager.playerVersion = "Html V" + this.appVersionService.version;
        }
      );
    }
  }

  public bootService(){
    /**
     * we need to use this service somewere before it gets loaded by angular
     * Injection in another service/view is enough, but we provide a method call so it will not be cleaned up by accident (without a call it looks like an unnecessary import/injection)
    */
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    if (this.intercomDataManager != null){
      this.intercomDataManager.pingIntervalInSeconds = undefined;
    }

    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

}
