import { Injectable, Inject } from '@angular/core';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';
import { LOGLEVEL, stringToLogLevel, LogTarget } from './logTarget';
import { ConsoleLogTarget } from './consoleLogTarget';
import { Api4LogTarget } from './api4LogTarget';
import { LoggingApiService } from '../api/logging-api.service';

const GUID_KEY = "tunify_guid";

@Injectable()
export class LoggerService {


  public set forceLogLevel(value: LOGLEVEL){
    this.logTargets.forEach(logTarget => {
      logTarget.adjustLogLevel(value);
    });
  }

  public set webserviceLogLevel(value: LOGLEVEL){
    if (this.api4LogTarget.currentLogLevel > value){
      this.api4LogTarget.adjustLogLevel(value);
    }
  }




  private consoleLogTarget = new ConsoleLogTarget();
  private api4LogTarget: Api4LogTarget;
  private logTargets: LogTarget[] = [this.consoleLogTarget];


  //the loggerService can't depend on other services, because it is used in all other services
  constructor(
    private loggingApiService: LoggingApiService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {

    this.api4LogTarget = new Api4LogTarget(this.loggingApiService, this);
    this.logTargets.push(this.api4LogTarget);

    const environmentConsoleLogLevel = stringToLogLevel(environment.consoleLogLevel);
    if (environmentConsoleLogLevel){
      console.debug("changing default console logLevel to: " + environment.consoleLogLevel);
      this.consoleLogTarget.adjustLogLevel(environmentConsoleLogLevel);
    }else{
      console.error("environment console logLevel not recognized: " + environment.consoleLogLevel);
    }

    const environmentWebServiceLogLevel = stringToLogLevel(environment.webServiceLogLevel);
    if (environmentWebServiceLogLevel){
      console.debug("changing webservice console logLevel to: " + environment.webServiceLogLevel);
      this.api4LogTarget.adjustLogLevel(environmentWebServiceLogLevel);
    }else{
      console.error("environment webservice logLevel not recognized: " + environment.webServiceLogLevel);
    }

    this.initGUID();
  }

  private initGUID(){
    let localStorageGuid = this.storage.get(GUID_KEY);
    if (localStorageGuid == null){
       localStorageGuid = Math.floor(Math.random() * 10000000000);
       this.storage.set(GUID_KEY, localStorageGuid);
    }
    this.api4LogTarget.guid = "" + localStorageGuid;
  }


  /**
   * Information on the last seen zone. When a zone is logged out, we still log information on the last seen session.
   */
  public adjustToLoggedInZone(zoneId: number){
    this.api4LogTarget.lastSeenZoneId = zoneId + '';
  }

  public registerNewPlayToken(playToken: string){
    this.api4LogTarget.lastSeenPlayToken = playToken;
  }


  /**
   * Logger methods
   */
  public info(classname: string, method: string, message: string) {
    this.logTargets.forEach(logTarget => {
      logTarget.log(LOGLEVEL.INFO, classname, method, message);
    });
  }

  public debug(classname: string, method: string, message: string) {
    this.logTargets.forEach(logTarget => {
      logTarget.log(LOGLEVEL.DEBUG, classname, method, message);
    });
  }

  public warn(classname: string, method: string, message: string) {
    this.logTargets.forEach(logTarget => {
      logTarget.log(LOGLEVEL.WARN, classname, method, message);
    });
  }

  public error(classname: string, method: string, message: string) {
    this.logTargets.forEach(logTarget => {
      logTarget.log(LOGLEVEL.ERROR, classname, method, message);
    });
  }




  public flushBuffer() {
    this.logTargets.forEach(logTarget => {
      logTarget.flushBuffer();
    });
  }




  // this.runningTimeString = moment("1900-01-01 00:00:00").add(this.progress, 'seconds').format("m:ss");


}
