import { slideUpAnimation } from '@util/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ListRange } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CdkVirtualForOf } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { TrackTableItem } from '@components/components-v5/track-list-view/track-list-view.component';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { AudioFile } from '@model/audioFile';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { Playlist } from '@model/playlist';
import { TranslateService } from '@ngx-translate/core';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { PlaylistService } from '@service/playlist.service';
import { QueueService } from '@service/queue.service';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { BehaviorSubject, Subject, combineLatest, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DeletePlaylistConfirmPopupComponent } from '@components/popups-v5/delete-playlist-confirm-popup/delete-playlist-confirm-popup.component';
import { SubscriptionsService } from '@service/subscriptions.service';
import { TrackIndexAction, TrackIndexEvent } from '@model/events/trackIndexEvent';

export enum PlaylistState{
  normal,
  creating,
  deleting,
  deleted,
  loading,
  loadError,
  empty,
  none
}

@Component({
  selector: 'tun-playlist-detail-content-view',
  templateUrl: './playlist-detail-content-view.component.html',
  styleUrls: ['./playlist-detail-content-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})
export class PlaylistDetailContentViewComponent implements OnDestroy {

  @Output() changeName = new EventEmitter<Playlist>();
  @Output() addSongs = new EventEmitter<Playlist>();

  /**
   * BUG SEMI FIX:
   *
   * Virtual scrolling and autoscroll during drag&drop does not work
   *
   * Strategy:
   * Less than 100 items: no virtual scrolling and allow autoscroll
   * 100 items+: virtual scrolling and disable autoscroll
   */

  public LOGGER_NAME = PlaylistDetailContentViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public TunifyColor = TunifyColor
  public TunifyCheckBoxColor = TunifyCheckBoxColor

  public get showingPopup(){
    return this.showChoosePlaylistOverlay;
  }
  public showChoosePlaylistOverlay = false


  public tracks: AudioFile[] = [];
  public get hasTracks(): boolean{
    return this.tracks != null && this.tracks.length > 0;
  }

  private _showPlaylist: Playlist;
  private showingPlaylistChanged$ = new Subject<void>();
  private tracksChanged$ = new Subject<void>();
  @Input()
  set showPlaylist(value: Playlist) {
    this._showPlaylist = value

    this.showingPlaylistChanged$.next();
    if (this.showPlaylist){
      this.showPlaylist.audioFiles$
        .pipe(
          takeUntil(
            merge(
              this.showingPlaylistChanged$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          audioFiles => {

            if (audioFiles){
              this.tracks = audioFiles
            }else{
              this.tracks = [];
            }

            this.tracksChanged$.next();

          }
        )
    }else{
      this.tracks = [];
    }

    this.changeDetectorRef.detectChanges();
  }
  get showPlaylist():Playlist{
    return this._showPlaylist;
  }

  get canEditProperties(): boolean {
    return this._showPlaylist != null && this.playlistService.playlists.filter(playlist => playlist.id == this._showPlaylist.id).length > 0;
  }


  public get title(){
    if (this.showPlaylist){
      return this.showPlaylist.title
    }
    return ""
  }

  //make the enum available in the html template
  public PlaylistState = PlaylistState;

  get playlistState(): PlaylistState{
    if (this.showPlaylist){
      if (this.showPlaylist.creatingPlaylist){
        return PlaylistState.creating;
      }else if (this.showPlaylist.deletingPlaylist){
        return PlaylistState.deleting;
      }else if (this.showPlaylist.deleted){
        return PlaylistState.deleted;
      }else if (this.showPlaylist.detailsLoading){
        return PlaylistState.loading;
      }else if (this.showPlaylist.detailsLoadingError != null){
        return PlaylistState.loadError;
      }else if (this.showPlaylist.audioFiles == null){
        return PlaylistState.none;
      }else if (this.showPlaylist.audioFiles.length == 0){
        return PlaylistState.empty;
      }
      return PlaylistState.normal;
    }
    return PlaylistState.none;
  }

  get audioFileProperty$(){
    return this.zoneConfigurationService.audioFileProperty$
  }


  constructor(
    private loggerService: LoggerService,
    private zoneConfigurationService: ZoneConfigurationService,
    private appV5StateService: AppV5StateService,
    private changeDetectorRef: ChangeDetectorRef,
    private musicManipulationService: MusicManipulationService,
    private queueService: QueueService,
    private playlistService: PlaylistService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private trackOverlayService: TrackOverlayService,
    private dialog: MatDialog,
    private subscriptionsService: SubscriptionsService
  ) {

  }


  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onBack(){
    this.appV5StateService.showPlaylistInTunifyOrange(null);
  }


  /* Handlers for header */

  public onPlayAll(){
    if (this.showPlaylist && this.showPlaylist.audioFiles){
      if (this.showPlaylist.audioFiles.length > 0){
        const tracksToQueue: AudioFile[] = [];
        this.showPlaylist.audioFiles.forEach(track => tracksToQueue.push(createClassObjectForAudioFile(track)));

        const trackToPlay = tracksToQueue.shift()
        this.musicManipulationService.playAudioFile(trackToPlay);

        if (tracksToQueue.length > 0){
          this.queueService.clearQueue();
          this.queueService.addAudioFilesToQueue(tracksToQueue);
        }

        this.checkedTracks = [];
      }
    }
  }


  /* Handlers for track item */

  public onShowOptions(track: AudioFile){
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = new TrackInfoOverlayData(track, new TrackInfoContext(TrackInfoContextMode.playlist, this.showPlaylist));
  }

  public checkedTracks : AudioFile[] = [];
  onCheckedTracksChanged(checkedTracks: AudioFile[]){
    this.checkedTracks = checkedTracks;
  }
  onAddTracksToPlaylist(){
    this.showChoosePlaylistOverlay = true
  }


  /* Select playlist popup */
  onCloseSelectPlaylist(playlist: Playlist){
    if (playlist && this.checkedTracks.length > 0){
      this.playlistService.addAudioFileToPlaylist(playlist, this.checkedTracks)
      .pipe(
        takeUntil(
          merge(
            this.tracksChanged$,
            this.destroyed$
          )
        )
      ).subscribe(
        (asyncStatus) => {
          if (asyncStatus == AsyncStatus.COMPLETED){

              const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });

              this.checkedTracks = [];

          }
        }
      );
    }
    this.showChoosePlaylistOverlay = false;
  }


  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;
    this.showChoosePlaylistOverlay = false;
  }


  /* Drag & drop */
  public onTrackIndexEvent(event: TrackIndexEvent){
    if (event.action == TrackIndexAction.MOVE_TO_INDEX){
      this.playlistService.moveAudioFileInPlaylist(this.showPlaylist, [event.track], event.index);
    }else if (event.action == TrackIndexAction.ADD_AT_INDEX){
      this.playlistService.addAudioFileToPlaylist(this.showPlaylist, [event.track], event.index);
    }
  }

  onAddSongs(){
    if (this.showPlaylist){
      this.addSongs.next(this.showPlaylist);
    }
  }

  onChangeName(){
    if (this.showPlaylist){
      this.changeName.next(this.showPlaylist);
    }
  }

  onDelete(){
    if (this.showPlaylist){
      this.playlistService.deletePlaylist(this.showPlaylist);
    }
  }
}
