<div class="wrapper">

  <div class="contentWrapper {{showingPopup ? 'blurredContent' : ''}}">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button
          [matTooltip]="backTooltipForSection(previousSectionTitle)"
          class="icon-button header-icon-button"
          (click)="onBack()"
        >
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <button
          [disabled]="!(hasTracks$ | async)"
          [matTooltip]="'trackOptions.list.playAll' | translate"
          class="icon-button first-title-item {{checkedTracks.length > 0 ? 'no-focus' : ''}}"
          (click)="onPlayAll()"
        >
          <tun-play-icon-v5 class="icon colored-icon"></tun-play-icon-v5>
        </button>

        <div class="title">
          <button
            [matTooltip]="backTooltipForSection('search.tab.label' | translate)"
            class="title-link-button"
            (click)="onBackToSearch()"
          >
            {{'search.tab.label' | translate }}:
          </button>

          <button *ngIf="previousSectionTitle != null"
            [matTooltip]="backTooltipForSection(previousSectionTitle)"
            class="title-link-button"
            (click)="onBack()"
          >
            {{previousSectionTitle}}:
          </button>

          <button
            [disabled]="checkedTracks.length > 0"
            [matTooltip]="refreshTooltip()"
            class="title-link-button"
            (click)="onRefresh()"
          >
            {{title}}
            <tun-refresh-icon-v5 class="icon icon-small refresh-icon"></tun-refresh-icon-v5>
          </button>
        </div>



        <button
          [disabled]="!(hasTweaks$ | async) || checkedTracks.length > 0"
          [matTooltip]="((hasTweaks$ | async) ? 'trackOptions.list.tweak.adjust' : 'trackOptions.list.tweak.noTweaks') | translate"
          class="icon-button"
          (click)="onTweak()"
        >
          <tun-menu-music-channel-icon-v5 class="icon"></tun-menu-music-channel-icon-v5>
        </button>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <ng-container *ngIf="musicCollection$ | async">



      <!-- Special playlist states indicator -->

      <div
        *ngIf="searchState != SearchState.normal"
        [ngSwitch]="searchState"
        class="feedback-container"
      >
        <div
          *ngSwitchCase="SearchState.searchError"
          class="error-feedback-container"
        >
          <p>
            {{'search.status.failed' | translate }}
          </p>
          <button (click)="retryClick()" class="retryButton">
            <p>
              {{'general.tryAgain' | translate }}
            </p>
          </button>
        </div>

        <ng-container *ngSwitchCase="SearchState.searching">
          <tun-loader-v5 class="loader"></tun-loader-v5>
        </ng-container>

      </div>

      <tun-selectable-track-list-view
        *ngIf="searchState == SearchState.normal"
        class="trackTable"
        [tracks]="tracks$ | async"
        [checkedTracks]="checkedTracks"
        [audioFileProperty]="audioFileProperty$ | async"
        (checkedTracksChanged)="onCheckedTracksChanged($event)"
        (addTracksToPlaylist)="onAddTracksToPlaylist($event)"
        (showOptions)="onShowOptions($event)"
      >
      </tun-selectable-track-list-view>



    </ng-container>


  </div>


  <!-- Popup: choose playlist-->
  <div #choosePlaylistPopupWrapper *ngIf="showChoosePlaylistOverlay" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
    <tun-overlay-select-playlist
      class="overlay-panel"
      confirmButtonTranslateableText="playlist.addTrack.popup.button.commit"
      (closeWithPlaylist)="onCloseSelectPlaylist($event)"
    >
    </tun-overlay-select-playlist>
  </div>

  <!-- Popup: tweak musicCollection -->
  <div #tweakPopupWrapper *ngIf="showTweakPopup" class="popupWrapper" @slideUpAnimation (mousedown)="onClickOutside($event, tweakPopupWrapper)">
    <tun-overlay-tweak-music-collection
      class="overlay-panel"
      [musicChannel]="showingMusicChannel$ | async"
      [musicCollection]="musicCollection$ | async"
      (parametersChanged)="onParametersChanged($event)"
      (close)="onCloseTweakPopup()"
    >
    </tun-overlay-tweak-music-collection>
  </div>



</div>

