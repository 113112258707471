<div *ngIf="musicCollection" class="wrapper {{musicCollection.selected ? 'selected' : ''}}" (click)="onToggleSelected()">

  <div class="contentWrapper">

    <div class="titleContainer">
      <tun-scrollable-text class="title" [text]="musicCollection.title"></tun-scrollable-text>
    </div>

    <tun-music-play-animation
      class="playAnimationContainer"
      *ngIf="showPlayAnimation$ | async"
      [playing]="animatePlayAnimation$ | async"
      [musicPlayAnimationColors]="playAnimationColors"
    >
    </tun-music-play-animation>

    <button class="icon-button" (click)="onTweak($event)">
      <tun-tweak-context-icon-v5 *ngIf="!showAsPlaylist(musicCollection)" class="icon icon-scaled-svg-button"></tun-tweak-context-icon-v5>
      <tun-tweak-playlist-icon-v5 *ngIf="showAsPlaylist(musicCollection)" class="icon icon-scaled-svg-button"></tun-tweak-playlist-icon-v5>
    </button>

  </div>


  <div *ngIf="isRecommendation" class="ai-container">
    <div class="ai-icon-container">
      <tun-ai-stars-v5 class="icon icon-large-scaled-svg-button ai-icon"></tun-ai-stars-v5>
    </div>

  </div>
</div>

